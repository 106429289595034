import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import styles from './styles.module.scss';
import FormFeedback from '../../../../components/Forms/FormFeedback';
import Modal from '../../../../components/Modal';
import { objectIsEmpty } from '../../../../util/helpers';

import { validationSchema } from './validation';
import { DELIVERY_METHODS } from './constants';
import {
    FormDescription,
    GiftAmountSection,
    DeliveryMethodSection,
    RecipientInfoSection,
    MessageSection,
    SenderInfoSection
} from './components';

const CREATE_GIFT_CERTIFICATE = gql`
  mutation createPaypalGiftCertificatePurchase(
    $data: PaypalGiftCertificatePurchaseCreateInput!

  ) {
    createPaypalGiftCertificatePurchase(
      data: $data
    ) {
        data
    }
  }
`;

const GiftCertificatesForm = () => {
    const [formStatus, setFormStatus] = useState(null);
    const [showMore, setShowMore] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [previewMode, setPreviewMode] = useState(null);
    const [createPaypalGiftCertificatePurchase, { loading: mutationLoading }] = useMutation(CREATE_GIFT_CERTIFICATE);

    const getRedemptionValue = (giftCertificateId) => {
        console.log('giftCertificateId', giftCertificateId)

        if (giftCertificateId == 1) return 1000;
        if (giftCertificateId == 2) return 2500;
        if (giftCertificateId == 3) return 5000;
        if (giftCertificateId == 4) return 7500;
        if (giftCertificateId == 5) return 10000;


        return 0
    }
    useEffect(() => {
        $('[data-toggle="popover"]').popover({
            container: 'body',
        });
        return () => {
            $('[data-toggle="popover"]').popover('dispose');
        };
    }, []);

    const moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return (
        <Formik
            initialValues={{
                giftCertificateId: '',
                recipientName: '',
                deliveryMethod: DELIVERY_METHODS.EMAIL,
                recipientEmail: '',
                address: '',
                city: '',
                state: '',
                country: 'US',
                postalCode: '',
                message: '',
                fromName: '',
                fromEmail: '',
                additionalInformation: '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, setStatus, setErrors }) => {
                try {
                    setSubmitting(true);

                    const response = await createPaypalGiftCertificatePurchase({
                        variables: {
                            data: {
                                giftCertificateId: parseInt(values.giftCertificateId),
                                recipientName: values.recipientName,
                                deliveryMethod: values.deliveryMethod,
                                recipientEmail: values.recipientEmail,
                                address: values.address,
                                city: values.city,
                                state: values.state,
                                country: values.country,
                                postalCode: values.postalCode,
                                message: values.message,
                                fromName: values.fromName,
                                fromEmail: values.fromEmail,
                                additionalInformation: values.additionalInformation,
                            }

                        },
                    });

                    console.log('response', response)

                    if (response.data?.createPaypalGiftCertificatePurchase?.data?.links[1]?.href) {
                        window.location.href = response.data?.createPaypalGiftCertificatePurchase?.data?.links[1]?.href;
                    }

                    setFormStatus({ success: true });
                } catch (err) {
                    console.error('Error submitting gift card:', err);
                    setSubmitting(false);
                    setStatus({
                        error: 'There was a problem with your submission. Please check your data and try again.',
                    });
                    setErrors({
                        message: 'There was a problem with your submission. Please check your data and try again.',
                    });
                }
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                validateForm,
                isSubmitting,
                submitCount,
                status,
            }) => (



                <form onSubmit={handleSubmit} className={styles.GiftCertificateForm}>
                    <FormDescription showMore={showMore} setShowMore={setShowMore} />

                    <div className="row">
                        <div className="col col-12 col-lg-4">
                            <GiftAmountSection
                                value={values.giftCertificateId}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                touched={touched.giftCertificateId}
                                error={errors.giftCertificateId}
                            />

                            <DeliveryMethodSection
                                value={values.deliveryMethod}
                                onChange={handleChange}
                                touched={touched.deliveryMethod}
                                error={errors.deliveryMethod}
                            />

                            <RecipientInfoSection
                                values={values}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                            />

                            <MessageSection
                                value={values.message}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                touched={touched.message}
                                error={errors.message}
                            />

                            <SenderInfoSection
                                values={values}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                            />

                            <FormFeedback
                                errors={status?.error}
                                isVisible={
                                    status?.error ||
                                    formStatus?.success ||
                                    (!objectIsEmpty(errors) && submitCount > 0)
                                }
                                alert={!objectIsEmpty(errors) && submitCount > 0}
                            >
                                Redirecting to Paypal Secure Checkout...
                            </FormFeedback>

                            <div className={styles.buttonGroup}>

                                <button type="button" onClick={() => validateForm().then((formErrors) => {
                                    console.log('formErrors', formErrors)
                                    if (Object.keys(formErrors).length > 0) {
                                        console.log('formErrors2', formErrors)
                                        handleSubmit();
                                        setShowPreviewModal(false);
                                    } else {
                                        console.log('none', formErrors)
                                        setShowPreviewModal(true);
                                    }

                                })} className={`text-primary ${styles.previewButton}`}>
                                    Preview
                                </button>
                                <button
                                    type="submit"
                                    className={`text-primary ${styles.nextButton}`}
                                    disabled={isSubmitting}
                                >
                                    {!mutationLoading && <span>Payment &gt;</span>}
                                    {mutationLoading && (
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className="col d-none d-lg-block col-8">
                            <img src="./bhip-form-gift-certificate.jpg" />
                        </div>
                    </div>
                    <Modal
                        isOpen={showPreviewModal}
                        disableFocusTrap={true}
                        onClose={() => {
                            setShowPreviewModal(false)
                            setPreviewMode(null)
                        }}
                        title={"Gift Certificate Preview"}
                    >

                        <div className={styles.buttonGroup} style={{ marginTop: '3px', marginBottom: '15px' }}>

                            <button type="button" onClick={() => {
                                setShowPreviewModal(false);
                                setPreviewMode(null)
                            }} className={`text-primary ${styles.previewButton}`}>
                                &lt; Close Preview
                            </button>
                            {values.deliveryMethod == 'both' && <button
                                type="button"
                                onClick={() => {
                                    //  togglePreviewMode()
                                    setPreviewMode(previewMode == 'overnight' ? 'email' : 'overnight');
                                }}
                                className={`text-primary ${styles.nextButton}`}

                            >
                                {previewMode == 'overnight' ? 'Email ' : 'Letter '} Preview
                            </button>}
                            <button
                                type="button"
                                onClick={() => {
                                    handleSubmit()
                                }}
                                className={`text-primary ${styles.nextButton}`}
                                disabled={isSubmitting}
                            >
                                {!mutationLoading && <span>Payment &gt;</span>}
                                {mutationLoading && (
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                            </button>
                        </div>

                        {((values.deliveryMethod == 'both' && (previewMode == 'email' || previewMode == null)) || (values.deliveryMethod == 'email' && (previewMode == 'email' || previewMode == null))) && <>
                            <div className="mb-2">
                                <b>To:</b> {values.recipientEmail}
                            </div>
                            <div className="mb-2">
                                <b>From:</b> {values.fromName}
                            </div>
                            <div className="mb-2">
                                <b>Subject:</b> {values.recipientName}, you've received a Gift Certificate from {values.fromName}
                            </div>
                        </>}

                        <div className="text-center">
                            <img src={`./gc-${getRedemptionValue(values.giftCertificateId)}.png`} />
                            {((values.deliveryMethod == 'both' && previewMode == 'overnight') || (values.deliveryMethod == 'overnight')) && <img className="mt-1plus mb-2" src={`./certificate-envelope.jpg`} />}
                        </div>

                        <FormFeedback
                            errors={status?.error}
                            isVisible={
                                status?.error ||
                                formStatus?.success ||
                                (!objectIsEmpty(errors) && submitCount > 0)
                            }
                            alert={!objectIsEmpty(errors) && submitCount > 0}
                        >
                            Redirecting to Paypal Secure Checkout...
                        </FormFeedback>

                        {(previewMode == 'overnight' || (values.deliveryMethod == 'overnight' && previewMode == null)) ? <>
                            <div className="mt-2 mb-2">
                                <b>To:</b> {values.recipientName}
                            </div>
                            <div className="mb-2">
                                <b>From:</b> {values.fromName}
                            </div>

                            <div className="mb-2">
                                <b>Delivery:</b>

                                <div>
                                    <div>{values.streetAddress}</div>
                                    {values.streetAddress && <div>{values.city} {values.state} {values.postalCode} {values.country}</div>}
                                </div>


                            </div>
                            <div className="mb-2">
                                <b>Message:</b> {values.message}
                            </div>
                        </> : <>
                            <div className="mt-2">
                                <b>Message:</b> {values.message}
                            </div>
                        </>} <hr />
                        <div>
                            <div className="mb-2">
                                <b>Guarantee:</b> The presenter of this gift certificate is entitled to a {moneyFormatter.format(getRedemptionValue(values.giftCertificateId)).replace(".00", '')} discount off the published rental rate of any Beach House in Paradise, as seen on any travel platform, such as Vrbo.com, Airbnb.com, and Booking.com, or at www.BeachHousesInParadise.com. This gift certificate has no expiration date.
                            </div>
                            <div >
                                <b>How to redeem:</b> When communicating with Beach Houses in Paradise, including on any travel platform, please provide your gift certificate redemption code.
                            </div>
                            <hr />
                            <div>
                                For security purposes, please note that the redemption code is unique and should not be shared with others.
                            </div>
                        </div>
                    </Modal>
                </form >


            )
            }
        </Formik >
    );
};

export default GiftCertificatesForm;
