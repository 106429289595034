import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';

import gql from 'graphql-tag';
import Loadable from '@loadable/component';
import * as Yup from 'yup';
// const { Formik, Field } = Loadable(() => import('formik')); // code-splitted, on demand loaded component
import { Formik, Field } from 'formik';
import styles from './styles.module.scss';
import './styles.scss';
import WithLocation from '../WithLocation';

const DatePicker = Loadable(() => import('../DatePicker')); // code-splitted, on demand loaded component

import { formatDateWithSlashes } from '../../util/dateHelper';
import { objectIsEmpty } from '../../util/helpers';
import EventTracker from '../EventTracker';
import FormFeedback from '../Forms/FormFeedback';
import PseudoSelect from '../PseudoSelect';
import GuestCounterForm from './components/GuestCounterForm';
import GroupCheckListForm from './components/GroupCheckListForm';
import ThemedSelectList from '../ThemedSelectList';
// import Recaptcha from 'react-recaptcha';

// let recaptchaInstance;

const GUEST_GROUP_TYPES = gql`
  query GuestGroupTypes {
    guestGroupTypes {
      id
      label
    }
  }
`;

const CREATE_GUEST_LEAD = gql`
  mutation createGuestLead(
    $name: String!
    $email: String!
    $message: String!
    $emailConsent: String
    $checkInPreference: Date
    $phoneNumber: PhoneNumber
    $localTraveler: GuestLeadLocalTraveler
    $referrer: String
    $checkOutPreference: Date
    $targetPropertyId: Int
    $numberOfAdults: Int
    $numberOfChildren: Int
    $numberOfPets: Int
    $guestGroupTypeIds: [Int]
    $numberOfBathrooms: Int
    $numberOfBedrooms: Int
    $numberOfVehicles: Int
    $numberOfVisitors: Int
  ) {
    createGuestLead(
      data: {
        name: $name
        email: $email
        message: $message
        emailConsent: $emailConsent
        referrer: $referrer
        phoneNumber: $phoneNumber
        localTraveler: $localTraveler
        checkInPreference: $checkInPreference
        checkOutPreference: $checkOutPreference
        targetPropertyId: $targetPropertyId
        numberOfAdults: $numberOfAdults
        numberOfChildren: $numberOfChildren
        numberOfPets: $numberOfPets
        guestGroupTypeIds: $guestGroupTypeIds
        numberOfBathrooms: $numberOfBathrooms
        numberOfBedrooms: $numberOfBedrooms
        numberOfVehicles: $numberOfVehicles
        numberOfVisitors: $numberOfVisitors
      }
    ) {
      id
    }
  }
`;

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const RequestInfoForm = props => {
  // const defaultCheckInDate = new Date();
  // const defaultCheckOutDate = new Date(
  //   defaultCheckInDate.getTime() + 1 * 72 * 60 * 60 * 1000
  // );
  const { requestCheckIn, targetPropertyId, location = {} } = props;
  const searchParams = props?.location?.location?.search;
  const params = new URLSearchParams(searchParams);

  const [formStatus, setFormStatus] = useState(null);
  const [guestInfoSelectLabel, setGuestInfoSelectLabel] = useState(null);

  const [guestNumbersLabel, setGuestNumbersLabel] = useState(null);
  const [guestGroupTypesLabel, setGuestGroupTypesLabel] = useState(
    'Check all that apply'
  );
  const [checkInDatepickerOpen, setCheckInDatepickerOpen] = useState(false);
  const [checkOutDatepickerOpen, setCheckOutDatepickerOpen] = useState(false);
  // introduced state below to allow datepicker to close when user selects the same date
  const [startDate, setStartDate] = useState(
    params.get('datepreference')
      ? moment(params.get('datepreference').split('-')[0])
      : null
  );
  const [endDate, setEndDate] = useState(
    params.get('datepreference')
      ? moment(params.get('datepreference').split('-')[1])
      : null
  );
  const checkInLabel = moment(startDate).format('MM/DD/YYYY');
  const checkOutLabel = moment(endDate).format('MM/DD/YYYY');

  const [selectedDatesSelectLabel, setSelectedDatesSelectLabel] = useState(
    startDate && endDate ? `${checkInLabel} - ${checkOutLabel}` : null
  );
  console.log('startDate', startDate);
  const [createGuestLead, { data, loading: mutationLoading }] = useMutation(
    CREATE_GUEST_LEAD
  );
  const datePreferences = {};

  datePreferences.checkIn = Yup.date()
    .when('noDates', {
      is: false, // alternatively: (val) => val == true
      then: Yup.date()
        .required('Please select your date preference')
        .nullable(),
    })
    .nullable();
  datePreferences.checkOut = Yup.date()
    .when('noDates', {
      is: false, // alternatively: (val) => val == true
      then: Yup.date()
        .required('Please select your date preference')
        .nullable(),
      // otherwise: yup.number().min(0),
    })
    .nullable();
  // }

  const validationSchema = Yup.object().shape({
    emailAddress: Yup.string()
      .email('Please enter a valid email')
      .required('Email is required'),
    name: Yup.string().required('Name is required'),
    additionalDetails: Yup.string().required('Additional details are required'),
    localTraveler: Yup.string().required(
      'Please let us know if you are local to South Florida'
    ),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(phoneRegExp, 'Phone number is not valid'),
    // guestGroupIds: Yup.array()
    //   .of(Yup.number().required('Please tell us about your group'))
    //   .nullable(),
    guestGroupIds: Yup.array()
      .required('Please tell us about your group')
      .nullable(),
    ...datePreferences,
  });
  const { data: guestGroupData, error, loading: guestGroupLoading } = useQuery(
    GUEST_GROUP_TYPES
  );

  const guestGroupTypes = guestGroupData?.guestGroupTypes || [];
  // return (
  //   <>
  //     {' '}
  //     For more information, please contact us at{' '}
  //     <a href="mailto:beachhousesinparadise@gmail.com">
  //       beachhousesinparadise@gmail.com
  //     </a>
  //   </>
  // );
  console.log('startDate', startDate);
  console.log('endDate', endDate);
  // console.log(
  //   'startDate&',
  //   startDate != null && endDate != null ? 'false' : 'true'
  // );
  return (
    <>
      <EventTracker>
        {({ trackEvent }) => {
          return (
            <Formik
              // enableReinitialize={true}
              initialValues={{
                emailAddress: '',
                name: '',
                checkIn: startDate ? startDate : '',
                checkOut: endDate ? endDate : '',
                phoneNumber: '',
                emailConsent: 'Y',
                noDates: false, //startDate && endDate ? false : true,
                localTraveler: '',
                // checkIn: startDate,
                // checkOut: endDate,
                additionalDetails: '',
                targetPropertyId,
                numberOfAdults: '',
                numberOfChildren: '',
                numberOfPets: '',
                numberOfVehicles: '',
                numberOfVisitors: '',
                bedrooms: '',
                baths: '',
                guestGroupIds: null,
              }}
              validationSchema={validationSchema}
              onSubmit={async (
                values,
                { setSubmitting, setStatus, setErrors, resetForm }
              ) => {
                try {
                  // executeCaptcha();
                  //   handleSubmit(e);

                  setSubmitting(true);
                  // console.log('FORM submitted!', values);
                  // return;

                  const submissionData = {
                    name: values.name,
                    email: values.emailAddress,
                    phoneNumber: values.phoneNumber,
                    emailConsent: values.emailConsent,
                    localTraveler: values.localTraveler,
                    message: values.additionalDetails,
                    referrer: window.location.href,
                    checkInPreference:
                      values.checkIn && values.checkIn !== ''
                        ? values.checkIn.toISOString()
                        : null,

                    checkOutPreference:
                      values.checkOut && values.checkOut !== ''
                        ? values.checkOut.toISOString()
                        : null,
                    numberOfAdults:
                      values.numberOfAdults === ''
                        ? null
                        : values.numberOfAdults,
                    numberOfChildren:
                      values.numberOfChildren === ''
                        ? null
                        : values.numberOfChildren,
                    numberOfBedrooms:
                      values.bedrooms === '' ? null : Number(values.bedrooms),
                    numberOfBathrooms:
                      values.baths === '' ? null : Number(values.baths),
                    numberOfPets:
                      values.numberOfPets === '' ? null : values.numberOfPets,
                    numberOfVehicles:
                      values.numberOfVehicles === ''
                        ? null
                        : Number(values.numberOfVehicles),
                    numberOfVisitors:
                      values.numberOfVisitors === ''
                        ? null
                        : Number(values.numberOfVisitors),
                    targetPropertyId: values.targetPropertyId,
                    guestGroupTypeIds: values.guestGroupIds,
                  };

                  createGuestLead({
                    variables: submissionData,
                  })
                    .then(result => {
                      // setSubmitting(false);
                      trackEvent('Forms', { formType: 'Request Info' });
                      setFormStatus({ success: true });
                      // resetForm();
                      // setStartDate(defaultCheckInDate);
                      // setEndDate(defaultCheckOutDate);
                    })
                    .catch(err => {
                      console.log('err', err);
                      setSubmitting(false);
                      setStatus({
                        error:
                          'There was a problem with your submission. Please check your data and try again.',
                      });
                      setErrors({
                        message:
                          'There was a problem with your submission. Please check your data and try again.',
                      });
                    });
                } catch (err) {
                  console.log('err', err);
                  setSubmitting(false);
                  setStatus({
                    error:
                      'There was a problem with your submission. Please check your data and try again.',
                  });
                }
              }}
            >
              {formikProps => {
                const {
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  submitCount,
                  status,
                } = formikProps;
                console.log('values', values);
                return (
                  <form data-request-info-form="true" onSubmit={handleSubmit}>
                    <div className="mb-2">
                      <div className="form-group">
                        <label htmlFor="name">
                          <b>Name</b>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control rounded"
                          name="name"
                          id="name"
                          placeholder="Enter full name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className="small text-danger mt-1">
                          {touched.name && errors.name && errors.name}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">
                          <b>Email</b>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <input
                          name="emailAddress"
                          type="email"
                          className="form-control rounded"
                          id="email"
                          placeholder="Enter email (authorizes contact)"
                          onChange={handleChange}
                        />
                        <div className=" small text-danger mt-1">
                          {touched.emailAddress &&
                            errors.emailAddress &&
                            errors.emailAddress}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="phoneNumber">
                          <b>Phone</b>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <input
                          name="phoneNumber"
                          type="text"
                          className="form-control rounded"
                          id="phoneNumber"
                          placeholder="Enter phone (authorizes contact)"
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <div className=" small text-danger mt-1">
                          {touched.phoneNumber &&
                            errors.phoneNumber &&
                            errors.phoneNumber}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="treavelDatePreference">
                          <b>Travel Date Preference</b>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        {checkInDatepickerOpen && (
                          <div className="dropdown">
                            <div data-keep-open>
                              {' '}
                              <DatePicker
                                selectsStart
                                onClickOutside={() =>
                                  setCheckInDatepickerOpen(false)
                                }
                                name="checkIn"
                                type="text"
                                className="form-control rounded"
                                id="checkIn"
                                placeholder=""
                                minDate={new Date()}
                                // maxDate={values.checkOut ? values.checkOut : null}
                                startDate={startDate}
                                endDate={endDate}
                                selected={startDate}
                                value={startDate}
                                withPortal
                                inline
                                // customInput={<CustomDatePickerInput />}
                                onChange={args => {
                                  // setFieldValue('checkIn', args);
                                  // setFieldValue('noDates', false);
                                  function compareTime(time1, time2) {
                                    return new Date(time1) > new Date(time2); // true if time1 is later
                                  }

                                  setStartDate(args);
                                  setCheckInDatepickerOpen(false);
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {checkOutDatepickerOpen && (
                          <div className="dropdown">
                            <div data-keep-open>
                              <DatePicker
                                selectsEnd
                                onClickOutside={() =>
                                  setCheckOutDatepickerOpen(false)
                                }
                                popperPlacement="bottom-start"
                                // customInput={<CustomDatePickerInput />}
                                name="checkOut"
                                type="text"
                                className="form-control rounded"
                                id="checkOut"
                                placeholder=""
                                minDate={startDate ? startDate : new Date()}
                                startDate={startDate}
                                endDate={endDate}
                                inline
                                onChange={args => {
                                  // setFieldValue('checkOut', args);
                                  setEndDate(args);
                                  setCheckOutDatepickerOpen(false);
                                  // setFieldValue('noDates', false);
                                }}
                                withPortal
                              />
                            </div>
                          </div>
                        )}

                        <PseudoSelect
                          label={selectedDatesSelectLabel || 'Select Dates'}
                          id="date-preference"
                        >
                          <div className="m-2">
                            <div className="d-flex justify-content-between mb-2">
                              <div
                                className={`${styles.datePickerFormGroup} form-group mr-2`}
                                style={{ flexGrow: '1' }}
                              >
                                <label htmlFor="checkIn">Check In Date</label>
                                <button
                                  disabled={values.noDates}
                                  type="button"
                                  id="check-in-input"
                                  className="btn w-100 text-left border form-control"
                                  onClick={() => {
                                    setCheckInDatepickerOpen(
                                      prevState =>
                                        !prevState.checkInDatepickerOpen
                                    );
                                  }}
                                >
                                  {startDate &&
                                    formatDateWithSlashes(startDate)}
                                </button>
                                <span className="small">5pm or later</span>
                              </div>
                              <div
                                className={`${styles.datePickerFormGroup} form-group`}
                                style={{ flexGrow: '1' }}
                              >
                                <label htmlFor="checkOut">Check Out Date</label>
                                <button
                                  id="check-out-input"
                                  disabled={values.noDates || !startDate}
                                  type="button"
                                  className="btn w-100 text-left border form-control"
                                  onClick={() => {
                                    setCheckOutDatepickerOpen(
                                      prevState =>
                                        !prevState.checkOutDatepickerOpen
                                    );
                                  }}
                                >
                                  {endDate && formatDateWithSlashes(endDate)}
                                </button>
                                <span className="small">11am or earlier</span>
                              </div>
                            </div>
                            <div className="form-check custom-control custom-checkbox mb-2">
                              <input
                                className="form-check-input custom-control-input"
                                type="checkbox"
                                value={values.noDates}
                                id={`noDates`}
                                name={'noDates'}
                                onClick={e => {
                                  setFieldValue('noDates', !values.noDates);
                                  console.log('noDates', !values.noDates);
                                  if (!values.noDates) {
                                    setFieldValue('checkIn', null);
                                    setFieldValue('checkOut', null);
                                    setStartDate(null);
                                    setEndDate(null);
                                  }
                                }}
                              />
                              <label
                                className={`form-check-label custom-control-label`}
                                htmlFor={`noDates`}
                              >
                                Not sure yet
                              </label>
                            </div>
                            <button
                              id="date-apply-button"
                              className="btn btn-outline-primary"
                              onClick={() => {
                                if (values.noDates) {
                                  setSelectedDatesSelectLabel(`Not sure yet`);
                                  setFieldValue('noDates', true);
                                  setFieldValue('checkIn', null);
                                  setFieldValue('checkOut', null);
                                  setSelectedDatesSelectLabel(`Not sure yet`);
                                } else {
                                  const checkInLabel = moment(startDate).format(
                                    'MM/DD/YYYY'
                                  );
                                  const checkOutLabel = moment(endDate).format(
                                    'MM/DD/YYYY'
                                  );

                                  if (startDate && endDate) {
                                    setSelectedDatesSelectLabel(
                                      `${checkInLabel} - ${checkOutLabel}`
                                    );
                                  }

                                  setFieldValue('noDates', false);
                                  setFieldValue('checkIn', startDate);
                                  setFieldValue('checkOut', endDate);
                                }
                              }}
                              type="button"
                              data-close
                            >
                              Apply
                            </button>
                          </div>
                        </PseudoSelect>
                        <div
                          id="date-preference-feedback"
                          className="small text-danger mt-1"
                        >
                          {touched.checkIn &&
                            (errors.checkIn || errors.checkOut)}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="checkIn">
                          <b>How many are you?</b>
                        </label>
                        <PseudoSelect label={guestNumbersLabel || 'Guest Info'}>
                          <GuestCounterForm
                            formikProps={formikProps}
                            onSubmit={label => setGuestNumbersLabel(label)}
                          />
                        </PseudoSelect>
                      </div>
                      <div className="form-group">
                        <label htmlFor="checkIn">
                          <b>Looking for a house with</b>
                        </label>
                        <div className="d-flex justify-content-between">
                          <ThemedSelectList
                            disableContext={true}
                            onSelectChange={value =>
                              setFieldValue('bedrooms', value)
                            }
                            name="bedrooms"
                            id="bedrooms-select-form"
                            options={[
                              { label: 'Any', value: '' },
                              { label: '4+', value: '4' },
                              { label: '5+', value: '5' },
                              { label: '6+', value: '6' },
                            ]}
                            classNames={`mr-1plus rounded`}
                            hiddenValue="Bedrooms"
                          />
                          <ThemedSelectList
                            disableContext={true}
                            onSelectChange={value =>
                              setFieldValue('baths', value)
                            }
                            name="baths"
                            id="baths-select"
                            options={[
                              { label: 'Any', value: '' },
                              { label: '4+', value: '4' },
                              { label: '5+', value: '5' },
                              { label: '6+', value: '6' },
                            ]}
                            classNames={`rounded`}
                            hiddenValue="Baths"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="localTraveler">
                          <b>Are you local to South Florida?</b>
                          <span className="text-danger ml-1">*</span>
                        </label>
                        <select
                          value={values.localTraveler}
                          name="localTraveler"
                          className="form-control mr-2 custom-select-list"
                          id="localTraveler"
                          onChange={e => {
                            setFieldValue('localTraveler', e.target.value);
                          }}
                        >
                          <option hidden>Select Option</option>
                          <option value="YES">Yes &mdash; we are local</option>
                          <option value="NO">
                            No &mdash; we will be flying or driving in
                          </option>
                          <option value="BOTH">
                            Both &mdash; we are locals and non-locals
                          </option>
                        </select>

                        <div className="small text-danger mt-1">
                          {touched.localTraveler &&
                            errors.localTraveler &&
                            errors.localTraveler}
                        </div>
                      </div>

                      <div className="form-group">
                        <label>
                          <b>Tell us about your group</b>
                        </label>
                        <span className="text-danger ml-1">*</span>

                        <PseudoSelect
                          id="group-info-select"
                          label={guestGroupTypesLabel}
                        >
                          {guestGroupTypes.length > 0 && (
                            <GroupCheckListForm
                              onSubmit={label => setGuestGroupTypesLabel(label)}
                              formikProps={formikProps}
                              guestGroupTypes={guestGroupTypes}
                            />
                          )}
                        </PseudoSelect>

                        <div
                          id="group-feedback"
                          className="small text-danger mt-1"
                        >
                          {touched.guestGroupIds &&
                            errors.guestGroupIds &&
                            errors.guestGroupIds}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="additionalDetails">
                          <b>Additional Details</b>
                        </label>
                        <span className="text-danger ml-1">*</span>
                        <textarea
                          name="additionalDetails"
                          className={`${styles.textArea} form-control rounded`}
                          id="additionalDetails"
                          placeholder=""
                          onChange={handleChange}
                        />
                        <div className="small text-danger mt-1">
                          {touched.additionalDetails &&
                            errors.additionalDetails &&
                            errors.additionalDetails}
                        </div>
                      </div>



                    </div>
                    <FormFeedback
                      errors={status?.error}
                      isVisible={
                        status?.error ||
                        formStatus?.success ||
                        (!objectIsEmpty(errors) && submitCount > 0)
                      }
                      alert={!objectIsEmpty(errors) && submitCount > 0}
                    >
                      Thank you for your interest. We will be in touch shortly.
                    </FormFeedback>
                    {/* {objectIsEmpty(errors) && (formStatus || {}).success && (
                      <div
                        className="alert alert-success alert-dismissible fade show"
                        role="alert"
                      >
                        <strong>
                          Thank you for your interest. We will be in touch
                          shortly.
                        </strong>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    )} */}
                    {/* {errors.message && (
                      <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                      >
                        {errors.message}
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    )} */}
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="btn btn-secondary text-primary font-weight-bold rounded w-100"
                    >
                      {!mutationLoading && <span>Send</span>}
                      {mutationLoading && (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </button>

                    {/* <Recaptcha
              ref={e => {
                recaptchaInstance = e;
              }}
              sitekey="6LddnqoUAAAAAI_y2-IM12DUYVg-jLRXTPM9_Z6o"
              // size="invisible"
              verifyCallback={verifyCallback}
            /> */}
                  </form>
                );
              }}
            </Formik>
          );
        }}
      </EventTracker>
    </>
  );
};

RequestInfoForm.defaultProps = {
  requestCheckIn: true,
};

export default WithLocation(RequestInfoForm);
