import React, { Component, useEffect, useState, useRef } from 'react';
// import DateRangePicker from 'react-daterange-picker';
import DateRangePicker from '../DaterangePicker/src/index.js';
import WithLocation from '../WithLocation';
import Moment from 'moment-timezone';

import { extendMoment } from 'moment-range';
import { Query, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { Router, Link } from '@reach/router';
import '../DaterangePicker/src/css/react-calendar.scss';

const CALENDAR_DATA = gql`
  query PropertyData($key: String) {
    property(where: { key: $key }) {
      name
      id
      sitePage {
        slug
      }
      vacancies: vacanciesFromCalendar {
        start
        end
        minStay
        nightsAvailable
      }
      calendar {
        start
        end
        availability
        checkInRestriction
        checkOutRestriction
        minPriorNotify
        minStay
      }
    }
  }
`;
const QUOTE_DATA = gql`
  query PropertyQuote($propertyId: Int, $checkIn: Date, $checkOut: Date, $promoCode: String) {
    propertyQuote(
      where: { propertyId: $propertyId, checkIn: $checkIn, checkOut: $checkOut, promoCode: $promoCode }
    ) {
      baseRate
      rate
      cleaningFee
      petFee

      
      damageDeposit
      avgPerNight
      totalNights

      availablePromo {
        terms
      }
      promoDiscount {
        percent
        baseRateDiscount
        baseRateDiscountApplied
        rate
        tax {
          lodgingTax
          countyTax
          stateTax
        }
      }      
      property {
        stateTaxRate
        countyTaxRate
        petFee
      }
    }
  }
`;
const moment = extendMoment(Moment);
const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const Picker = ({ propertyKey, promo, location }) => {
  // console.log('propertyKey', propertyKey);
  console.log('utcdate', moment('2024-07-06').utc())
  console.log('utcdate2', moment().utc())
  //console.log('utcdate3', moment().tz("Europe/London"))
  console.log('utcdate4', moment().tz("America/New_York"))
  const { data, error, loading, refetch } = useQuery(CALENDAR_DATA, {
    variables: { key: propertyKey },
  });

  const property = data?.property || {};
  // console.log('property', property);
  const params = new URLSearchParams(location.location.search);
  // const [selectedRange, setSelectedRange] = useState({ value: null });
  // const [selectedStart, setSelectedStart] = useState({ value: null });
  // const [initialValue, setInitialValue] = useState(null);

  const [selectedRange, setSelectedRange] = useState({ value: params.get('checkIn') ? moment.range(moment(params.get('checkIn')), moment(params.get('checkOut'))) : null });
  const [selectedStart, setSelectedStart] = useState({ value: params.get('checkIn') ? moment(params.get('checkIn')) : null });
  const [initialValue, setInitialValue] = useState(params.get('checkIn') ? moment.range(moment(params.get('checkIn')), moment(params.get('checkOut'))) : null);

  const [defaultStartDate, setDefaultStartDate] = useState(params.get('checkIn') ? new Date(params.get('checkIn')) : new Date());
  const propertyQuoteTableRef = useRef(null);
  const propertyQuoteDiscountRef = useRef(null);
  const minimumStayNotMetRef = useRef(null);
  console.log('selectedRange', selectedRange)
  const {
    data: quoteData,
    error: quoteError,
    loading: quoteLoading,
  } = useQuery(QUOTE_DATA, {
    variables: {
      propertyId: data?.property.id,
      checkIn: selectedRange.value?.start,
      checkOut: selectedRange.value?.end,
      promoCode: promo
    },
    skip: selectedRange.value == null,
  });
  if (quoteLoading && minimumStayNotMetRef.current) {
    minimumStayNotMetRef.current.style.display = 'none';
  }
  // console.log('quoteData', quoteData);
  const [refreshCalendarKey, setRefreshCalendarKey] = useState(Date.now());
  const [ranges, setRanges] = useState([]);
  const [hasEndDate, setHasEndDate] = useState(false);

  const validationErrors =
    quoteError?.graphQLErrors[0]?.extensions?.exception?.validationErrors;
  //if (validationErrors) setFormFeedback(validationErrors);
  useEffect(() => {
    if (quoteLoading == false && params.get('checkIn')) {
      console.log('quoteLoading!', quoteLoading)
      console.log('quoteData!', quoteData)
      console.log('quoteError1!', quoteError)
      if (quoteError) {
        setSelectedRange({ value: null });
        setSelectedStart({ value: null });
        console.log('quoteError!', quoteError.message)
        console.log('quoteError!', Object.keys(quoteError))
      }

    }
  }, [quoteLoading])

  if (loading) return null;

  const getNumberOfConsecutiveDaysAvailable = (
    dateKey,
    availabilityCalendar
  ) => {
    const allDates = Object.keys(availabilityCalendar);
    const dateRange = allDates.slice(allDates.indexOf(dateKey));
    const consecutiveDates = [];
    dateRange.some(date => {
      consecutiveDates.push(availabilityCalendar[date]);
      return availabilityCalendar[date].availability === 'N';
    });
    return consecutiveDates.slice(0, consecutiveDates.length - 1).length;
  };

  const getAvailabilityCalendar = property => {
    const {
      availability: availabilityString,
      checkInRestriction: checkInRestrictionString,
      checkOutRestriction: checkOutRestrictionString,
      minStay: minStayString,
      minPriorNotify: minPriorNotifyString,
    } = property.calendar;
    // console.log('property.calendar', property.calendar)
    const availability = availabilityString.split('');
    const checkInRestriction = checkInRestrictionString.split('');
    const checkOutRestriction = checkOutRestrictionString.split('');
    const minStay = minStayString.split(',');
    const minPriorNotify = minPriorNotifyString.split(',');
    const calendarStartDate = property.calendar.start;
    const availabilityCalendarBase = checkInRestriction.reduce(
      (acc, day, index) => {
        const dateKey = moment(calendarStartDate)
          .utc()
          .add(index, 'days')
          .format('YYYY-MM-DD');
        acc[`${dateKey}`] = {
          dateKey,
          availability: availability[index],
          checkInRestriction: checkInRestriction[index],
          checkOutRestriction: checkOutRestriction[index],
          minStay: minStay[index],
          minPriorNotify: minPriorNotify[index],
        };

        return acc;
      },
      {}
    );

    const availabilityCalendar = Object.keys(availabilityCalendarBase).reduce(
      (acc, dateKey) => {
        const numberOfConsecutiveDaysAvailable = getNumberOfConsecutiveDaysAvailable(
          dateKey,
          availabilityCalendarBase
        );
        const daysFromNow =
          moment(dateKey)
            .utc()
            .diff(moment().utc(), 'days') + 1;
        acc[dateKey] = {
          ...availabilityCalendarBase[dateKey],
          numberOfConsecutiveDaysAvailable,
          minPriorNotifyFail:
            daysFromNow < availabilityCalendarBase[dateKey].minPriorNotify
              ? true
              : false,
        };
        return acc;
      },
      {}
    );

    return availabilityCalendar;
  };
  // console.log('acal', getAvailabilityCalendar(property));
  const availabilityCalendarDictionary = getAvailabilityCalendar(property);
  const availabilityCalendarValues = Object.values(
    availabilityCalendarDictionary
  );

  // console.log('availabilityCalendarValues', availabilityCalendarValues);
  // console.log('availabilityCalendarDictionary', availabilityCalendarDictionary);
  // console.log('availabilityCalendarDictionary', property.calendar);

  // if (minimumStayNotMetRef.current) {

  //   minimumStayNotMetRef.current.style.display = 'block';
  //   //  propertyQuoteTableRef.current.style.display = 'none';
  // }


  const handleSelect = (range, states) => {
    // range is a moment-range object
    console.log('handleSelect:', range);
    if (propertyQuoteTableRef.current) {

      minimumStayNotMetRef.current.style.display = 'none';
      propertyQuoteTableRef.current.style.display = 'block';

    }
    if (propertyQuoteDiscountRef.current) {
      propertyQuoteDiscountRef.current.style.display = 'block';
    }

    setSelectedRange({
      value: range,
      states: states,
    });
  };
  const handleSelectStart = date => {
    if (propertyQuoteTableRef.current) {
      propertyQuoteTableRef.current.style.display = 'none';
    }
    if (propertyQuoteDiscountRef.current) {
      propertyQuoteDiscountRef.current.style.display = 'none';
    }
    // range is a moment-range object
    // setHasEndDate(false)
    // console.log('handleSelectStart:date', date);
    // setSelectedRange({
    //   value: null,
    //   states: selectedRange.states,
    // });
    // setSelectedStart({ value: date });
  };
  const stateDefinitions = {
    available: {
      color: null,
      label: 'Available',
    },
    nocheckin: {
      disabled: true,
      selectable: false,
      label: 'No Checkin',
    },
    enquire: {
      color: '#ffd200',
      label: 'Enquire',
    },
    unavailable: {
      selectable: false,
      color: '#cccccc',
      label: 'Unavailable',
    },
  };

  const availabilityCalendarToVacancies = property => {
    const { start, availability: availabilityString } = property.calendar;
    let day = start;
    const calendar = availabilityString.split('').reduce((acc, availability) => {
      if (availability === 'Y') {
        acc.push({
          start: day, // formatDate(day),
          resourceId: property.id,
        });
      }
      day = addDays(day, 1);
      return acc;
    }, []);
    return calendar;
  };

  const addDays = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };


  const vacancies = property?.vacancies || {};
  const availabilityCalendar = property?.calendar;
  const vacanciesFromCalendar = availabilityCalendarToVacancies(property);
  console.log('vacanciesFromCalendar', vacanciesFromCalendar)
  // console.log('data', data);
  // console.log('vacancies', vacancies);
  const availableRanges = vacancies
    .filter(vacancy => vacancy.nightsAvailable >= vacancy.minStay)
    .map(vacancy => {
      return {
        state: 'available',
        //range: moment.range(moment(vacancy.start), moment(vacancy.end)),
        range: moment.range(moment(vacancy.start).tz("America/New_York").format('YYYY-MM-DD'), moment(vacancy.end).tz("America/New_York").format('YYYY-MM-DD')),
        checkInRestrictions: 'NNNYYYYYYY',
      };
    });

  const dateRanges = [
    // {
    //   state: 'nocheckin',
    //   range: moment.range(moment('2023-09-29'), moment('2023-10-01')),
    // },
    // {
    //   state: 'nocheckin',
    //   range: moment.range(
    //     moment()
    //       .add(2, 'weeks')
    //       .subtract(5, 'days'),
    //     moment()
    //       .add(2, 'weeks')
    //       .add(6, 'days')
    //   ),
    // },
    // {
    //   state: 'available',
    //   range: moment.range(
    //     moment().add(3, 'weeks'),
    //     moment()
    //       .add(3, 'weeks')
    //       .add(5, 'days')
    //   ),
    // },
    ...availableRanges,
  ];

  return (
    <>
      <div className="flex text-right d-block d-md-none"><button
        class="btn  my-1 p-0 btn-link"
        onClick={() => {
          setDefaultStartDate(
            selectedRange.value?.start
              ? new Date(selectedRange.value?.start)
              : new Date()
          );
          setSelectedRange({ value: null });
          setRefreshCalendarKey(Date.now());
        }}
      >
        Clear Dates
      </button></div>
      <DateRangePicker
        key={refreshCalendarKey}
        numberOfCalendars={2}
        initialMonth={defaultStartDate.getMonth()}
        initialYear={defaultStartDate.getFullYear()}

        // availabilityCalendar={availabilityCalendar}
        // onRangeSelect={({ start, end }) => {
        //   setSelectedStart(start);
        // }}
        onRenderDateTitle={({ date }) => {
          const calendarData =
            availabilityCalendarDictionary[date.format('YYYY-MM-DD')];
          return params.get('debug') == 'true' ? JSON.stringify(calendarData, null, 2) : '';// '';// JSON.stringify(calendarData, null, 2);
        }}
        isDateSelectableOverride={({ date, state }) => {
          // console.log('method:isDateSelectableOverride');
          // // TODO: CHeck date for checkin availability. If none, prevent highlight / return false. Need to prevent click (selection) in same way
          // console.log('date', date);
          // console.log('debug0:state', state);
          //setSelectedStart(state.selectedStartDate);
          const isStartDateSelected = state.selectedStartDate != null; // First click in range selection.
          // if (!isStartDateSelected) {
          //   setSelectedRange({ value: null, state: state });
          // }
          const calendarData =
            availabilityCalendarDictionary[date.format('YYYY-MM-DD')];
          // console.log('calendarData', date.format('YYYY-MM-DD'));
          // console.log('calendarData', calendarData);
          // console.log('calendarData', calendarData?.checkInRestriction);
          // console.log('isDateSelectableOverride', calendarData)
          // console.log('isDateSelectableOverride:', isStartDateSelected)
          if (calendarData) {
            if (isStartDateSelected == false) {
              if (calendarData.checkInRestriction == 'Y') {
                console.log('calendarData:selectable1', false);
                return false;
              }
              if (
                calendarData.numberOfConsecutiveDaysAvailable <
                calendarData.minStay
              ) {
                return false;
              }
            } else {
              const selectedStartDateCalendarData =
                availabilityCalendarDictionary[
                state.selectedStartDate.format('YYYY-MM-DD')
                ];
              // console.log(
              //   'state.selectedStartDate',
              //   selectedStartDateCalendarData
              // );
              // console.log('state.selectedStartDate.date', calendarData);
              const daysBetween = date.diff(state.selectedStartDate, 'days');
              //console.log('state.selectedStartDate.daysBetween', daysBetween);
              // console.log(
              //   'availabilityCalendarDictionary1',
              //   availabilityCalendarDictionary
              // );
              if (
                daysBetween < Number(selectedStartDateCalendarData['minStay'])
              ) {
                return false;
              }
              if (calendarData.checkOutRestriction == 'Y') {
                //console.log('calendarData:selectable1', false);
                return false;
              }
            }

            // if (
            //   calendarData.checkInRestriction == 'Y' &&
            //   calendarData.availability == 'N' &&
            //   isStartDateSelected == false
            //   // &&
            //   // selectedStart == null &&
            //   // selectedRange.value == null
            // ) {
            //   return 'DateRangePicker__muted';
            // }

            // return true;
          }
          return true;
        }}
        onHighlightDatePreFlight={({ date, state }) => {
          // console.log('method:onHighlightDatePreFlight');
          // // TODO: CHeck date for checkin availability. If none, prevent highlight / return false. Need to prevent click (selection) in same way
          // console.log('debug:date', date);
          // console.log('debug:state', state);

          const calendarData =
            availabilityCalendarDictionary[date.format('YYYY-MM-DD')];
          //  console.log('calendarData', calendarData?.checkInRestriction);
          // setSelectedStart({ value: state.selectedStartDate });

          if (calendarData && state.selectedStartDate == null) {
            if (calendarData.checkInRestriction == 'Y') {
              return false;
            }

            if (
              calendarData.numberOfConsecutiveDaysAvailable <
              calendarData.minStay
            ) {
              return false;
            }

            return true;
          }
          return true;
        }}
        onDateCellClassNames={({ date, ...props }) => {
          // return '';
          const hasUnavailableDatesBetween = selectedStartDate => {
            const unavailableDateFound = availabilityCalendarValues.find(
              availabilityDate => {
                if (moment(date).isAfter(selectedStartDate)) {
                  // console.log('isAfter:', availabilityDate.dateKey);
                  // if ((availabilityDate.availability = 'N')) {
                  //   return true;
                  // }
                  // return true;
                }
                // if (moment(date).after(selectedStartDate)) {
                //   if
                // }
                // if (moment(date).isBetween('2010-10-19', '2010-10-25')

                // console.log(
                //   'availabilityDate:selectedStartDate',
                //   selectedStartDate
                // );
                // console.log('availabilityDate:date', date);
                // console.log('availabilityDate:targetDate', targetDate);
                // console.log('availabilityDate', availabilityDate);
              }
            );
            // return unavailableDateFound;
          };

          console.log('method:onDateCellClassNames');
          //availabilityCalendarDictionaryconsole.log('availabilityCalendar', availabilityCalendar);
          // const dateKey =
          const isStartDateSelected = props.highlightedRange != null;
          console.log('method:onDateCellClassNames:', isStartDateSelected);
          // console.log('debug:onDateCellClassNames:date', date);
          // console.log('debug:onDateCellClassNames:props', props);
          // console.log(
          //   'debug:onDateCellClassNames:selectedRange',
          //   selectedRange
          // );
          // console.log(
          //   'debug:onDateCellClassNames:availabilityCalendarDictionary',
          //   availabilityCalendarDictionary
          // );
          const calendarData =
            availabilityCalendarDictionary[date.format('YYYY-MM-DD')];

          const calendarDataNext =
            availabilityCalendarDictionary[
            moment(date)
              .utc()
              .add('day', 1)
              .format('YYYY-MM-DD')
            ];
          const calendarDataPrevious =
            availabilityCalendarDictionary[
            moment(date)
              .utc()
              .subtract('day', 1)
              .format('YYYY-MM-DD')
            ];
          //console.log('calendarData', calendarData?.checkInRestriction);

          // "minPriorNotify": "5",
          // "numberOfConsecutiveDaysAvailable": 0,

          // const isCheckoutAvailable = (selectedStartDate, availabilityCalendar);
          // console.log('method:onDateCellClassNames:calendarData', calendarData);

          if (calendarData) {
            // console.log('method:onDateCellClassNames:numberOfConsecutiveDaysAvailable', calendarData.numberOfConsecutiveDaysAvailable);
            // console.log('method:onDateCellClassNames:isStartDateSelected', isStartDateSelected);

            if (isStartDateSelected == false) {
              if (
                calendarData.numberOfConsecutiveDaysAvailable <
                calendarData.minStay || calendarData?.checkInRestriction == 'Y'
              ) {
                return 'DateRangePicker__muted';
              }
            } else {
              const selectedStartDate = props.highlightedRange.start;
              const selectedStartDateCalendarData =
                availabilityCalendarDictionary[
                selectedStartDate.format('YYYY-MM-DD')
                ];
              // console.log('onDateCellClassNames:date', date);
              // console.log('method:onDateCellClassNames:minStay', selectedStartDateCalendarData['minStay']);
              if (moment(date).isAfter(selectedStartDate)) {
                const calendarStart = availabilityCalendar.start;

                const substringStart = moment(selectedStartDate)
                  .utc()
                  .diff(moment(calendarStart).utc(), 'days');
                const substringEnd = moment(date)
                  .utc()
                  .diff(moment(calendarStart).utc(), 'days');
                const availabilityStringSegment = availabilityCalendar.availability.substring(
                  substringStart + 1,
                  substringEnd + 1
                );

                // console.log('onDateCellClassNames:isAfter:calendarStart', calendarStart);
                // console.log('onDateCellClassNames:isAfter:selectedStartDate', selectedStartDate);
                // console.log('onDateCellClassNames:isAfter:calendarStart.utc', moment(calendarStart).utc());
                // console.log('onDateCellClassNames:isAfter:calendarStart.date', date);
                // console.log('onDateCellClassNames:isAfter:calendarStart.substringStart', substringStart);

                //console.log('isAfter:', availabilityCalendar);
                //console.log('onDateCellClassNames:isAfter:availabilityStringSegment', availabilityStringSegment);
                // const unavailableDateBetween = hasUnavailableDatesBetween(
                //   selectedStartDate
                // );
                // console.log('dateafter:', date);
                // console.log('dateafter:', unavailableDateBetween);

                if (availabilityStringSegment.match(/N/)) {
                  if (
                    calendarData.availability == 'N' &&
                    calendarDataPrevious?.availability == 'Y' &&
                    calendarDataNext?.availability == 'N'
                  ) {
                    return 'DateRangePicker__link font-weight-bold  is-available-1';
                  } else {
                    return 'DateRangePicker__muted is-after-selected-start';
                  }
                }
              }

              // console.log(
              //   'debug:onDateCellClassNames:selectedStartDate',
              //   selectedStartDateCalendarData
              // );
              // console.log(
              //   'debug:onDateCellClassNames:selectedStartDate.date',
              //   calendarData
              // );
              const daysBetween = date.diff(selectedStartDate.utc(), 'days');
              console.log(
                'debug:onDateCellClassNames:selectedStartDate.daysBetween',
                daysBetween
              );
              if (
                daysBetween < Number(selectedStartDateCalendarData['minStay'])
              ) {
                // if (daysBetween > 0) {
                //   console.log('TOO SHORT')
                //   if (minimumStayNotMetRef.current) {

                //     minimumStayNotMetRef.current.style.display = 'block';
                //     //  propertyQuoteTableRef.current.style.display = 'none';
                //   }

                // } else {
                //   if (minimumStayNotMetRef.current) {

                //     minimumStayNotMetRef.current.style.display = 'none';
                //     //  propertyQuoteTableRef.current.style.display = 'none';
                //   }

                // }
                return (
                  'DateRangePicker__muted is-not-within-min-stay ' + daysBetween
                );
              }
            }
            // if (
            //   calendarData.checkInRestriction == 'Y' &&
            //   calendarData.availability == 'N' &&
            //   isStartDateSelected == false
            //   // &&
            //   // selectedStart == null &&
            //   // selectedRange.value == null
            // ) {
            //   return 'DateRangePicker__muted';
            // }
            // if (
            //   calendarData.checkInRestriction == 'Y' &&
            //   calendarData.availability == 'Y' &&
            //   isStartDateSelected == false
            //   // &&
            //   // selectedStart == null &&
            //   // selectedRange.value == null
            // ) {
            //   return 'DateRangePicker__muted';
            // }

            if (
              calendarData.checkOutRestriction == 'Y' &&
              calendarData.availability == 'Y' &&
              isStartDateSelected == true
              // &&
              // selectedStart == null &&
              // selectedRange.value == null
            ) {
              return 'DateRangePicker__muted is-checkout-restricted';
            }
            // if (
            //   calendarData.numberOfConsecutiveDaysAvailable <
            //     calendarData.minStay &&
            //   isStartDateSelected == false
            // ) {
            //   return 'DateRangePicker__muted';
            // }
            if (calendarData.availability == 'Y') {
              return 'DateRangePicker__link font-weight-bold  is-available';
            }
            // if (
            //   calendarData.availability == 'N' &&
            //   calendarDataPrevious?.availability == 'Y' &&
            //   calendarDataNext?.availability == 'N'
            // ) {
            //   return 'DateRangePicker__link font-weight-bold  is-available2';
            // }
            // if (date.format('YYYY-MM-DD') == '2023-11-11') {
            //   //     console.log('calendarDataPrevious', calendarDataPrevious);
            //   console.log(
            //     'calendarData:calendarDataPrevious',
            //     calendarDataPrevious
            //   );
            //   console.log('calendarData:calendarDataNext', calendarDataNext);
            // }
            return 'is-nothing';
          }
          // return 'text-muted';
          // TODO: CHeck date for checkin availability. If none, Make sure cell does not seem clickable. (muted text)
        }}
        selectionType="range"
        minimumDate={new Date()}
        stateDefinitions={stateDefinitions}
        dateStates={dateRanges}
        defaultState="unavailable"
        showLegend={false}
        value={selectedRange.value}
        //value={selectedStart.value}
        onSelect={handleSelect}
        onSelectStart={handleSelectStart}
        onDateClick={({ isSelectable }) => {
          // console.log('onDateClick:selectedRange.value', selectedRange.value)
          // console.log('onDateClick', props)
          minimumStayNotMetRef.current.style.display = isSelectable ? 'none' : 'block';
        }}
      />

      {!quoteLoading && validationErrors && (
        <div className="row">
          <div className="col">
            <span>{validationErrors[0].message}</span>
          </div>
        </div>
      )}
      <div class="row">
        <div class="col col-12  col-md-6">
          {quoteLoading && <span>Building your quote...</span>}
          <div ref={minimumStayNotMetRef} style={{ 'display': 'none' }}>Minimum stay length not met</div>
          {!quoteLoading && !validationErrors && quoteData?.propertyQuote && (
            <>

              <table ref={propertyQuoteTableRef} class="w-100 mb-2 mx-20">
                <tr>
                  <td>Avg. per night</td>
                  <td align="right">
                    {moneyFormatter.format(
                      quoteData?.propertyQuote?.avgPerNight
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>
                      Subtotal ({quoteData?.propertyQuote?.totalNights} X nights)
                    </b>
                  </td>
                  <td align="right">
                    <b style={{ 'textDecoration': promo == 'vacay50' && quoteData?.propertyQuote?.promoDiscount ? 'line-through' : '' }} > {moneyFormatter.format(quoteData?.propertyQuote?.baseRate)}</b>
                  </td>
                </tr>
                {
                  promo == 'vacay50' && quoteData?.propertyQuote?.promoDiscount && <>
                    <tr className="text-success">
                      <td>
                        <b>
                          Promotional Rate
                        </b>
                      </td>
                      <td align="right">
                        <b>{moneyFormatter.format(quoteData?.propertyQuote?.promoDiscount.baseRateDiscountApplied)}</b>
                      </td>
                    </tr>

                  </>
                }

              </table>

            </>
          )}
        </div>
        <div class="col col-12 d-flex align-items-start justify-content-end col-md-6">

          <div className="text-center">
            <a
              style={{ width: "170px" }}
              class={`btn btn-secondary rounded text-primary font-weight-bold ${(selectedRange.value ==
                null ||
                !quoteData?.propertyQuote) &&
                'disabled'}`}
              disabled={selectedRange.value == null || !quoteData?.propertyQuote}
              href={`/${property.sitePage.slug
                }/reserve?checkin=${selectedRange.value?.start.format(
                  'YYYY-MM-DD'
                )}&checkout=${selectedRange.value?.end.format('YYYY-MM-DD')}`}
            >
              {quoteLoading ? <>Building Quote</> : <>Continue</>}
            </a>
            <div className="text-muted small text-center">You won't be charged yet.</div>
            <button
              class="btn  my-1 p-0 btn-link"
              onClick={() => {
                setDefaultStartDate(
                  selectedRange.value?.start
                    ? new Date(selectedRange.value?.start)
                    : new Date()
                );
                setSelectedRange({ value: null });
                setRefreshCalendarKey(Date.now());
              }}
            >
              Clear Dates
            </button>
          </div>
        </div>
        {
          !quoteLoading && promo == 'vacay50' && <>
            {quoteData?.propertyQuote?.promoDiscount ? <>
              <div className="pl-2" ref={propertyQuoteDiscountRef} >

                {quoteData?.propertyQuote?.totalNights > 30 ? <>
                  Save 33% off of the owner's rate (applies to first 30 nights only)</> :
                  <>Save <b>{quoteData?.propertyQuote?.promoDiscount.percent}%</b> on the owners rate of <b>{moneyFormatter.format(quoteData?.propertyQuote?.baseRate)}</b></>
                }


              </div> </> : <>                {
                quoteData?.propertyQuote?.availablePromo && <>
                  <div className="pl-2" style={{ 'color': '#ff5722' }} ref={propertyQuoteDiscountRef} >

                    {quoteData?.propertyQuote?.availablePromo.terms} <img src="/last-minute-deals.svg" width="22" height="22" />

                  </div>

                </>

              }</>}
          </>
        }

      </div >

    </>
  );
};
export default WithLocation(Picker);
