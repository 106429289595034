import React, { useState, useEffect } from 'react';
// import DatePicker from 'react-datepicker';
import Loadable from '@loadable/component';
const DatePicker = Loadable(() => import('../../../../components/DatePicker')); // code-splitted, on demand loaded component
import './styles.scss';
import ScrollTable from '../../../../components/ScrollTable';
import {
  formatDateWithDashes,
  addDays,
  getDateRange,
} from '../../../../util/dateHelper';
import { Link } from 'gatsby';

const formatDatePickerLabel = (startDate, days) => {
  const endDate = addDays(startDate, days - 1);
  const startDateSplit = startDate.toUTCString().split(' ');
  const endDateSplit = endDate.toUTCString().split(' ');
  return `${startDateSplit[2]} ${startDateSplit[1]} - ${endDateSplit[2]} ${endDateSplit[1]
    }, ${endDateSplit[3]}`;
};

const PropertyResourceDisplay = ({ resource }) => {
  const childPropertyTextArray = resource?.childProperties?.reduce(
    (acc, property) => {
      if (property?.name) {
        acc.push(property?.name);
      }
      return acc;
    },
    []
  );
  const childPropertyTextString =
    childPropertyTextArray && childPropertyTextArray.join(', ');

  const popoverAttributes = {
    'data-trigger': 'hover',
    // 'data-container': 'body',
    'data-toggle': 'popover',
    'data-placement': 'top',
    'data-template':
      '<div class="popover custom-shadow" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>',
    'data-content': `Book multiple beach houses in a single reservation and Save. This beach house compound consists of ${childPropertyTextString}.`,
  };
  const thumbnailUrl =
    resource?.sitePage?.featuredImageSecondary?.file?.version?.fullUrl || '';
  return (
    <Link
      to={`/${resource?.sitePage?.slug}`}
      className="resource-title text-primary resource-link"
    >
      <div className="d-flex align-items-center">
        <div className="mr-1plus d-none d-lg-flex">
          <img
            width="92"
            height="69"
            className="no-resize"
            src={thumbnailUrl}
          />
        </div>
        <div className="property-list">
          <span className="text-primary font-weight-bold">
            {resource.title}
          </span>
          <br />
          <>
            <div className="d-flex justify-content-center">
              <span className="mr-1plus property-list-subtext" title="Bedrooms">
                <span className="mr-1">
                  <img width="26" height="20" src="/bed.svg" alt="Bedrooms" />
                </span>
                {resource.bedrooms}
              </span>
              <span className="mr-1plus property-list-subtext" title="Baths">
                <span className="mr-1">
                  <img width="20" height="20" src="/bath.svg" alt="Bathrooms" />
                </span>
                {resource.bathrooms}
              </span>
              <span className="mr-1plus property-list-subtext" title="Sleeps">
                <span className="mr-1">
                  <img width="16" height="20" src="/sleeps.svg" alt="Sleeps" />
                </span>
                {resource.sleeps}
              </span>
            </div>
            {resource?.type === 'COMBO' && (
              <div className={`my-1 p-0 tooltip-link`} {...popoverAttributes}>
                <span className="list-item">Compound Property</span>
              </div>
            )}
          </>
        </div>
      </div>
    </Link>
  );
};

const getDateCells = dateRange => {
  return (
    <>
      {dateRange.map((date, index) => {
        const dateString = date.toString().split(' ');
        const daySplit = dateString[2].split('');
        const dayDisplay = daySplit[0] === '0' ? daySplit[1] : dateString[2];
        return (
          <td
            key={index}
            className="position-relative"
            style={{
              width: '7.1428%',
              minWidth: '55px',
              textAlign: 'center',
              lineHeight: 1.2,
            }}
          >
            <>{`${dateString[0]}`}</>
            {date.getDate() === 1 ? (
              <div>
                <span>{`${date.toLocaleString('en-us', {
                  month: 'short',
                })} ${dayDisplay}`}</span>
              </div>
            ) : (
              <div>{`${dayDisplay}`}</div>
            )}
          </td>
        );
      })}
    </>
  );
};

const getResourceEventCells = (resource, events, dateRange) => {
  const resourceAvailability = (resource?.calendar?.availability || '').split(
    ''
  );

  const minStayThresholdAlerts = (
    resource?.calendar?.minStayThresholdAlert || ''
  ).split('');


  const dateString = (resource?.calendar?.start ? new Date(resource?.calendar?.start) : new Date()).toLocaleDateString('en-US', {
    timeZone: 'America/New_York'
  });

  const resourceStartDate = new Date(dateString);
  const eventsForResource = events.filter(
    event => event.resourceId == resource.id
  );
  return dateRange.map((date, index) => {
    const diffTime = Math.abs(date.getTime() - resourceStartDate.getTime());
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffDays = Math.floor(
      (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
        Date.UTC(
          resourceStartDate.getFullYear(),
          resourceStartDate.getMonth(),
          resourceStartDate.getDate()
        )) /
      (1000 * 60 * 60 * 24)
    );

    if (minStayThresholdAlerts[diffDays] === 'Y') {
      return (
        <td
          key={index}
          // data-container="th"
          //data-html="true"
          //data-toggle="tooltip"
          //data-placement="bottom"
          //data-template={`<div class="tooltip calendar-tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>`}
          // title="This property is subject to minimum-stay requirements, depending on season and availability. <br><br> When this home is vacant for shorter periods, the owner takes advantage of these brief vacancies to schedule maintenance and improvements."
          className={
            'position-relative reserved-full-day _min-stay-dots-centered'
          }
        >
          <div className="td-wrapper d-flex">
            {/* <span>
              <img className="dots" src="/threedots.svg" />
            </span> */}
          </div>
        </td>
      );
    }

    if (
      resourceAvailability[diffDays] === 'N' &&
      resourceAvailability[diffDays - 1] === 'Y' &&
      minStayThresholdAlerts[diffDays - 1] !== 'Y'
    ) {
      return <td key={index} className={'reserved-check-in'} />;
    } else if (
      resourceAvailability[diffDays] === 'Y' &&
      resourceAvailability[diffDays - 1] === 'N'
    ) {
      return <td key={index} className={'reserved-check-out'} />;
    } else if (resourceAvailability[diffDays] === 'N') {
      return <td key={index} className={'reserved-full-day'} />;
    }
    return <td key={index} />;
  });
};

const updateUrl = dateString => {
  window.history.pushState(
    null,
    null,
    window.location.pathname + `?start=${dateString}`
  );
};

const AvailabilityCalendar = ({ resources, events, days, ...rest }) => {
  const defaultDate = new Date();
  const [startDate, setStartDate] = useState(defaultDate);
  const dateRange = getDateRange(startDate, days);
  // const filteredPropertyList = resources.filter(
  //   property => (property.sitePage || {}).status === 'PUBLISHED'
  // );

  const filteredPropertyList = resources.filter(
    property =>
      (property.type == 'COMBO' ||
        property?.sitePage?.status === 'PUBLISHED') &&
      property.sitePage !== null
  );
  // const filteredPropertyList = resources;
  const params = new URLSearchParams(
    typeof window !== undefined && window.location.search
  );
  useEffect(() => {
    if (
      params.get('start') &&
      new Date(params.get('start')).toString() !== 'Invalid Date'
    ) {
      setStartDate(new Date(`${params.get('start')}`.replace(/-/g, '/')));
    } else {
      setStartDate(new Date());
    }
    $('.resource-link').on('click', e => {
      if ($(e.target).hasClass('list-item')) {
        e.preventDefault();
      }
    });
    $(window).on('click', () => {
      if ($('.react-datepicker-ignore-onclickoutside').length > 0) {
        $('.dropdown-caret').addClass('up');
        $('.dropdown-caret').removeClass('down');
      } else {
        $('.dropdown-caret').addClass('down');
        $('.dropdown-caret').removeClass('up');
      }
    });

    $('.tooltip-link').popover();
    // $('.tooltip-link').on('shown.bs.popover', function(e) {
    //   console.log('shown');
    //   e.preventDefault();
    //   e.stopPropagation();
    //   e.stopImmediatePropagation();
    // });

    const toolTips = document.querySelectorAll('[data-toggle="tooltip"] .dots');
    toolTips.forEach((element, index) => {
      $(element).tooltip({
        template: `<div class="tooltip calendar-tooltip text-left" role="tooltip"><div class="arrow"></div><div class="tooltip-inner text-left"></div></div>`,
        // name: 'flip',
        html: true,
        placement: 'bottom',
        title:
          '<span>This property is subject to minimum-stay requirements, depending on season and availability.<span> <br> <br> <span>When this home is vacant for shorter periods, the owner takes advantage of these brief vacancies to schedule maintenance and improvements.</span>',
        // name: 'flip',
        options: {
          boundary: document.querySelector('[data-scroll-table-container]'),
        },
      });
    });

    return () => {
      $(window).off('click');
      $('.resource-link').off('click');
      $('.tooltip-link').popover('dispose');
      toolTips.forEach(element => {
        if (element) {
          $(element).tooltip('dispose');
        }
      });
    };
  }, [params.get('start')]);

  return (
    <>
      {/* <div
        // data-toggle="tooltip"
        // key={amenity.key}
        className={`mt-1 mb-2 p-0 tooltip-link`}
        {...popoverAttributes}
      >
        <span className="list-item">Compound Property</span>
      </div> */}

      <div id="table-header" className="container">
        <div className="row table-header-row d-flex">
          <div className="navigation-column col-12 justify-content-center p-0 mt-1 mb-1plus mt-lg-0 ">
            <div className="text-center">
              <button
                className="btn text-primary px-0 px-md-1"
                onClick={() => {
                  setStartDate(new Date());
                  updateUrl(formatDateWithDashes(new Date()));
                }}
              >
                Today
              </button>
              <button
                disabled={
                  startDate.getTime() <= addDays(defaultDate, 1).getTime()
                }
                className="btn"
                onClick={() => {
                  setStartDate(addDays(startDate, days * -1));
                  updateUrl(
                    formatDateWithDashes(addDays(startDate, days * -1))
                  );
                }}
              >
                <img
                  width="11"
                  height="20"
                  src="/arrow-previous.svg"
                  alt="Previous"
                />
              </button>
              <button
                disabled={
                  addDays(defaultDate, 730).getTime() < startDate.getTime()
                }
                className="btn"
                onClick={() => {
                  setStartDate(addDays(startDate, days));
                  updateUrl(formatDateWithDashes(addDays(startDate, days)));
                }}
              >
                <img width="11" height="20" src="/arrow-next.svg" alt="Next" />
              </button>
            </div>
            <div className="text-center">
              <DatePicker
                popperModifiers={{
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                    boundariesElement: 'viewport',
                  },
                }}
                name="checkIn"
                type="text"
                className="btn rounded d-flex align-items-center px-0 px-md-1plus"
                id="datepicker"
                selectsStart
                selected={startDate}
                minDate={new Date()}
                maxDate={addDays(defaultDate, 730)}
                customInput={
                  <button>
                    <span>
                      {startDate
                        ? formatDatePickerLabel(startDate, days)
                        : formatDatePickerLabel(defaultDate, days)}
                    </span>
                    <span className="dropdown-caret down ml-1plus" />
                  </button>
                }
                onChange={args => {
                  updateUrl(formatDateWithDashes(args));
                  setStartDate(args);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="availability-table-wrapper">
        <ScrollTable startDate={startDate}>
          <table className="table table-bordered borderx-0">
            <thead>
              <tr>
                <th className="align-middle">Property</th>
                {getDateCells(dateRange)}
              </tr>
            </thead>
            <tbody>
              {filteredPropertyList.map((resource, index) => {
                return (
                  <tr key={index}>
                    <th style={{ fontWeight: 'normal' }}>
                      <PropertyResourceDisplay resource={resource} />
                    </th>
                    {getResourceEventCells(resource, events, dateRange)}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ScrollTable>
      </div>
    </>
  );
};

export default AvailabilityCalendar;
