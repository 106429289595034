import React from 'react';

const FormDescription = ({ showMore, setShowMore }) => {
    const description = "Give the gift of paradise on Florida's Treasure Coast, where our town — Stuart, FL — is the current winner of the 'Best Coastal Town in the USA' award (<em>USA Today</em> #1 national winner for 2024) Boasting thousands of 5-star reviews online, our high-end luxury beach houses are located <em>directly</em> on the beach, and less than a one-hour drive from nearby Palm Beach International Airport. Treat your family, friends and associates to a dream beach-house vacation, hosted by America's #1 beach-house rental management company, and located in America's #1 beach-house destination.";

    return (
        <>
            <div className="d-none d-md-block">
                <div className="mb-2" dangerouslySetInnerHTML={{ __html: description }}></div>
            </div>
            <div className="d-md-none">
                <div className="mb-2">
                    {!showMore ? (
                        <>
                            {description.substring(0, 40)}
                            <span>
                                ... <button
                                    className="btn btn-hyperlink text-primary p-0 align-inherit"
                                    style={{ verticalAlign: 'inherit' }}
                                    onClick={() => setShowMore(true)}
                                >
                                    more
                                </button>
                            </span>
                        </>
                    ) : (
                        <span dangerouslySetInnerHTML={{ __html: description }} />
                    )}
                </div>
            </div>
        </>
    );
};

export default FormDescription;
