import React, { useEffect } from 'react';
import SEO from '../../components/seo';
import Loading from '../../components/Loading';
import Layout from '../../layouts/DefaultLayout';
import CMSContent from '../../components/CMSContent';
import GiftCardsForm from './components/GiftCardsForm';
import { graphql } from 'gatsby';

import gql from 'graphql-tag';


import { useQuery, useMutation } from '@apollo/react-hooks';

// const GIFT_CERTIFICATE_PURCHASE = gql`
//   query GiftCertificatePurchase($token: String) {
//     giftCertificatePurchase(
//       where: { externalTransactionId: $token  }
//     ) {
//       redemptionCode
//     }
//   }
// `;
const GIFT_CERTIFICATE_CAPTURE = gql`
  mutation CapturePaypalGiftCertificatePurchase($externalTransactionId: String) {
    capturePaypalGiftCertificatePurchase(
      data: {externalTransactionId: $externalTransactionId}
    ) {
      data
    }
  }
`;

const GiftCards = ({ pageContext, location, ...props }) => {

  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  const { sitePage } = props.data.bhip;
  var searchParams = new URLSearchParams(location.search);
  const [capturePayment, { data, error, loading, called }] = useMutation(GIFT_CERTIFICATE_CAPTURE, {
    variables: { externalTransactionId: searchParams.get('token') },
  });



  const showSuccess = searchParams.get('paypal_status') == 'success';

  if (showSuccess && !called) {
    capturePayment();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (showSuccess) {

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);

    }
  }, [showSuccess, loading])

  console.log('location', location)
  console.log('showSuccess', showSuccess)
  console.log('data', data)


  if (!hasMounted) {
    return null;
  }


  return (
    <Layout hideSearchBar={true} searchbarLabel='Purchase a Gift Certificate'>
      <SEO
        title='Gift Certificates'
        description={sitePage.seoDescription}
        featuredImage={sitePage.featuredImage}
        headTags={sitePage.headTags}
      />
      {loading ? <div className="container mt-3 text-center">
        <Loading />
        Loading....
      </div> : <div className="container mt-3">
        <div className="mb-3">
          <CMSContent blockData={sitePage.cmsContent.blockData}>
            {sitePage.cmsContent.html}
          </CMSContent>
        </div>
        {showSuccess ? <>
          <div><b>Your gift certificate purchase is complete!</b></div>
          <ul className="mt-2">
            <li>For email delivery: Check your PayPal confirmation email for details</li>
            <li>For overnight letter: Tracking number will be sent to your email</li>
            <li>For both options: Digital copy will arrive shortly, physical certificate is being prepared</li>
          </ul>


          {/* <div>A confirmation will be sent to {data.recipientEmail}</div> */}


          <div>What happens next:</div>

          <ul className="mt-2">
            <li>Gift recipient will receive their certificate based on your chosen delivery method</li>
            <li>They can redeem it for any available dates</li>
            <li>Our concierge team will assist with their booking</li>
          </ul>

          Thank you for choosing Beach Houses in Paradise!
        </> : <GiftCardsForm />}
      </div>}
    </Layout>
  );
};

export const query = graphql`
  query GiftCards($slug: String) {
    bhip {
      sitePage(where: { slug: $slug }) {
        ...SitePageData
      }
    }
  }
`;

export default GiftCards;
