import React from 'react';
import { DELIVERY_METHODS, states } from '../constants';
import { countries } from '../countries';
import styles from '../styles.module.scss';

const RecipientInfoSection = ({
    values,
    handleChange,
    handleBlur,
    touched,
    errors
}) => {
    const showEmail = values.deliveryMethod === DELIVERY_METHODS.EMAIL ||
        values.deliveryMethod === DELIVERY_METHODS.BOTH;
    const showAddress = values.deliveryMethod === DELIVERY_METHODS.OVERNIGHT ||
        values.deliveryMethod === DELIVERY_METHODS.BOTH;

    return (
        <>
            <div className="form-group">
                <label htmlFor="recipientName">
                    <b>To</b>
                    <span className="text-danger ml-1">*</span>
                </label>
                <input
                    type="text"
                    id="recipientName"
                    name="recipientName"
                    className="form-control"
                    placeholder="Recipient's name"
                    value={values.recipientName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <div className="small text-danger mt-1">
                    {touched.recipientName && errors.recipientName && errors.recipientName}
                </div>
            </div>

            {showEmail && (
                <div className="form-group">
                    <label htmlFor="recipientEmail">
                        <b>Recipient Email</b>
                        <span className="text-danger ml-1">*</span>
                    </label>
                    <input
                        type="email"
                        id="recipientEmail"
                        name="recipientEmail"
                        className="form-control"
                        placeholder="recipient@example.com"
                        value={values.recipientEmail}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <div className="small text-danger mt-1">
                        {touched.recipientEmail && errors.recipientEmail && errors.recipientEmail}
                    </div>
                </div>
            )}

            {showAddress && (
                <div className={styles.addressSection}>
                    <div className="form-group">
                        <label htmlFor="streetAddress">
                            <b>Street Address</b>
                            <span className="text-danger ml-1">*</span>
                        </label>
                        <input
                            type="text"
                            id="streetAddress"
                            name="streetAddress"
                            className="form-control"
                            value={values.streetAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <div className="small text-danger mt-1">
                            {touched.streetAddress && errors.streetAddress && errors.streetAddress}
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="city">
                            <b>City</b>
                            <span className="text-danger ml-1">*</span>
                        </label>
                        <input
                            type="text"
                            id="city"
                            name="city"
                            className="form-control"
                            value={values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <div className="small text-danger mt-1">
                            {touched.city && errors.city && errors.city}
                        </div>
                    </div>

                    <div className={styles.stateZipRow}>
                        <div className="form-group">
                            <label htmlFor="state">
                                <b>State</b>
                                <span className="text-danger ml-1">*</span>
                            </label>
                            <select
                                id="state"
                                name="state"
                                className="form-control custom-select-list"
                                value={values.state}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <option value="" hidden>Select State</option>
                                {states.map(state => (
                                    <option key={state} value={state}>{state}</option>
                                ))}
                            </select>
                            <div className="small text-danger mt-1">
                                {touched.state && errors.state && errors.state}
                            </div>
                        </div>


                        <div className="form-group">
                            <label htmlFor="postalCode">
                                <b>ZIP Code</b>
                                <span className="text-danger ml-1">*</span>
                            </label>
                            <input
                                type="text"
                                id="postalCode"
                                name="postalCode"
                                className="form-control"
                                value={values.postalCode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                maxLength={10}
                                placeholder="12345"
                            />
                            <div className="small text-danger mt-1">
                                {touched.postalCode && errors.postalCode && errors.postalCode}
                            </div>
                        </div>


                        <div className="form-group d-none">
                            <label htmlFor="country">
                                <b>Country</b>
                                <span className="text-danger ml-1">*</span>
                            </label>
                            <select
                                id="country"
                                name="country"
                                className="form-control custom-select-list"
                                value={values.country}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                {countries.map(country => (
                                    <option key={country.code} value={country.code}>
                                        {country.name}
                                    </option>
                                ))}
                            </select>
                            <div className="small text-danger mt-1">
                                {touched.country && errors.country && errors.country}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default RecipientInfoSection;
