import React from 'react';
import { Info } from 'react-feather';
import { DELIVERY_METHODS } from '../constants';
import styles from '../styles.module.scss';
import moment from 'moment';

const DeliveryMethodSection = ({ value, onChange, touched, error }) => {
    const deliveryOptions = [
        {
            id: DELIVERY_METHODS.OVERNIGHT,
            label: 'Overnight Letter',
            tooltip: `The physical gift certificate itself is handsomely presented on high quality parchment paper, and elegantly inserted inside a luxury gilded envelope. Your certificate will be securely delivered to the recipient's address within 1 to 2 business days. ${moment().format('YYYY-MM-DD') < '2024-12-25' ? '(Christmas 2024: For delivery by Tuesday Dec 24, orders must be placed prior to 2pm Monday Dec 23).' : ''}`
        },
        {
            id: DELIVERY_METHODS.EMAIL,
            label: 'Email',
            tooltip: 'Choose email delivery for instant gifting. The digital gift certificate will be sent immediately to the recipient\'s email address, allowing them to start planning their beach getaway right away.'
        },
        {
            id: DELIVERY_METHODS.BOTH,
            label: 'Both',
            tooltip: 'Can\'t decide? Select "Both" to send a digital copy instantly via email and a physical certificate through overnight delivery. This option provides the immediacy of email with the tangible keepsake of a physical certificate.'
        }
    ];

    return (
        <div className="form-group d-none">
            <label>
                <b>Delivery Method</b>
                <span className="text-danger ml-1">*</span>
            </label>
            <div className={styles.radioGroup}>
                {deliveryOptions.map(({ id, label, tooltip }) => (
                    <div key={id} className="custom-control custom-radio">
                        <input
                            type="radio"
                            id={id}
                            name="deliveryMethod"
                            className="custom-control-input"
                            value={id}
                            checked={value === id}
                            onChange={onChange}
                        />
                        <label className="custom-control-label" htmlFor={id}>
                            {label}
                            <Info
                                className="ml-1"
                                width="16"
                                height="16"
                                color="#0069b4"
                                data-trigger="hover"
                                data-container="body"
                                data-toggle="popover"
                                data-placement="top"
                                data-template='<div class="popover custom-shadow" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>'
                                data-content={tooltip}
                            />
                        </label>
                    </div>
                ))}
            </div>
            <div className="small text-danger mt-1">
                {touched && error && error}
            </div>
        </div>
    );
};

export default DeliveryMethodSection;
