import React, { useState, useEffect } from 'react';
import Loadable from '@loadable/component';
// const { Formik, Field } = Loadable(() => import('formik')); // code-splitted, on demand loaded component
import { Formik, Field } from 'formik';

// import * as Yup from 'yup';
// import DatePicker from 'react-datepicker';

const DatePicker = Loadable(() => import('../../../../components/DatePicker')); // code-splitted, on demand loaded component
import WithLocation from '../../../../components/WithLocation';

import { updateUrl } from '../../../../util/urlHelper';
import { navigate } from 'gatsby';
import {
  formatDateWithSlashes,
  formatDateWithDashes,
} from '../../../../util/dateHelper';
import { objectIsEmpty } from '../../../../util/helpers';

// const AVAILABLE_PROPERTIES_QUERY = gql`
//   query Properties($checkIn: Date!, $checkOut: Date, $flexibilityInDays: Int) {
//     properties(
//       where: {
//         calendar: {
//           availability: {
//             checkIn: $checkIn
//             checkOut: $checkOut
//             flexibilityInDays: $flexibilityInDays
//           }
//         }
//       }
//     ) {
//       id
//     }
//   }
// `;

const formatUrlObject = values => {
  const urlObject = {};
  if (values.flexibilityInDays !== 0) {
    urlObject.flexibilityInDays = values.flexibilityInDays;
  }
  urlObject.checkIn = formatDateWithDashes(values.checkIn);
  urlObject.checkOut = formatDateWithDashes(values.checkOut);
  return urlObject;
};

const CheckAvailabilityForm = ({
  onClose,
  client,
  results,
  loading,
  location,
  ...props
}) => {
  console.log('location1', location)
  const defaultCheckInDate = new Date();
  const defaultCheckOutDate = new Date(
    defaultCheckInDate.getTime() + 1 * 72 * 60 * 60 * 1000
  );
  const [checkInDatepickerOpen, setCheckInDatepickerOpen] = useState(false);
  const [checkOutDatepickerOpen, setCheckOutDatepickerOpen] = useState(false);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        checkIn: '',
        checkOut: '',
        flexibilityInDays: 0,
      }}
      onSubmit={(
        values,
        { setSubmitting, setStatus, setErrors, resetForm }
      ) => {
        setSubmitting(true);
        try {
          const urlObject = {};
          formatUrlObject(values);
          updateUrl('rentals', {
            ...urlObject,
            // preserveCheckIn: true,
          });
          setSubmitting(false);
          resetForm();
        } catch (err) {
          console.log('err', err);
          setErrors({
            serverError:
              'Sorry, the date filter service is currently unavailable.',
          });
          setSubmitting(false);
        }
      }}
    >
      {formikProps => {
        const {
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          submitCount,
          status,
          resetForm
        } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <div className="container px-0">
              <div className="row">
                <div className="col-6 form-group pr-2">
                  <p className="font-weight-bold mb-1">Check In Date</p>
                  <button
                    id="check-in-input"
                    style={{ minHeight: '38px' }}
                    type="button"
                    className="btn w-100 text-left border"
                    onClick={() => {
                      setCheckInDatepickerOpen(
                        prevState => !prevState.checkInDatepickerOpen
                      );
                    }}
                  >
                    {values.checkIn !== ''
                      ? formatDateWithSlashes(values.checkIn)
                      : ''}
                  </button>
                  {checkInDatepickerOpen && (
                    <DatePicker
                      allowSameDay={true}
                      onClickOutside={() => setCheckInDatepickerOpen(false)}
                      selectsStart
                      startDate={values.checkIn}
                      endDate={values.checkOut}
                      selected={values.checkIn}
                      name="checkIn"
                      type="text"
                      className="form-control rounded"
                      id="checkIn"
                      withPortal
                      inline
                      minDate={new Date()}
                      onChange={args => {
                        setFieldValue('checkIn', args);
                        setCheckInDatepickerOpen(false);
                        if (values.checkOut) {
                          if (new Date(args) < new Date(values.checkOut)) {
                            const hrefWithParameters = 'https://www.beachhousesinparadise.com/rentals?' + (location.location.search ? `${location.location.search.slice(1)}&` : '');
                            //window.location.href = `${hrefWithParameters}$
                            const queryString = new URLSearchParams({
                              ...formatUrlObject({
                                ...values,
                                checkIn: args,
                              }),
                              // preserveCheckIn: true,
                            }).toString();
                            window.location.href = `${hrefWithParameters}${queryString}`;
                            // updateUrl('rentals', {
                            //   ...formatUrlObject({
                            //     ...values,
                            //     checkIn: args,
                            //   }),
                            //   // preserveCheckIn: true,
                            // });
                          } else {
                            setFieldValue('checkOut', '');
                          }
                        }
                      }}
                    />
                  )}
                  <div className="small text-danger mt-1">
                    {touched.checkIn && errors.checkIn && errors.checkIn}
                  </div>
                </div>
                <div className="col-6 form-group pl-0">
                  <p className="font-weight-bold mb-1">Check Out Date</p>{' '}
                  <button
                    id="check-out-input"
                    style={{ minHeight: '38px' }}
                    type="button"
                    className="btn w-100 text-left border"
                    onClick={() => {
                      setCheckOutDatepickerOpen(true);
                    }}
                  >
                    {values.checkOut !== ''
                      ? formatDateWithSlashes(values.checkOut)
                      : ''}
                  </button>
                  {checkOutDatepickerOpen && (
                    <DatePicker
                      allowSameDay={true}
                      onClickOutside={() => setCheckOutDatepickerOpen(false)}
                      selectsEnd
                      startDate={values.checkIn}
                      endDate={values.checkOut}
                      selected={values.checkIn}
                      minDate={new Date()}
                      name="checkOut"
                      type="text"
                      className="form-control rounded"
                      id="checkOut"
                      placeholderText="test"
                      withPortal
                      inline
                      onChange={args => {
                        setFieldValue('checkOut', args);
                        setCheckOutDatepickerOpen(false);
                        if (values.checkIn) {
                          if (new Date(args) > new Date(values.checkIn)) {
                            // updateUrl('rentals', {
                            //   ...formatUrlObject({
                            //     ...values,
                            //     checkOut: args,
                            //   }),
                            //   // preserveCheckIn: true,
                            // });
                          } else {
                            setFieldValue('checkIn', '');
                          }
                        }
                      }}
                    />
                  )}
                  <div className="small text-danger mt-1">
                    {touched.checkOut && errors.checkOut && errors.checkOut}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col form-group">
                  <select
                    name="flexibilityInDays"
                    value={values.flexibilityInDays}
                    // defaultValue="2"
                    onChange={e => {
                      setFieldValue('flexibilityInDays', e.target.value);
                      if (values.checkIn && values.checkOut) {
                        // updateUrl('rentals', {
                        //   ...formatUrlObject({
                        //     ...values,
                        //     flexibilityInDays: e.target.value,
                        //   }),
                        //   // preserveCheckIn: true,
                        // });
                      }
                    }}
                    className="custom-select-list rounded form-control select-list w-100"
                  >
                    <option value={0}>Not Flexible</option>
                    <option value={7}>Flexible for +/- 1 week</option>
                    <option value={14}>Flexible for +/- 2 weeks</option>
                  </select>
                  <div className="small text-danger mt-1">
                    {touched.flexibilityInDays &&
                      errors.flexibilityInDays &&
                      errors.flexibilityInDays}
                  </div>
                </div>
              </div>
              {errors.serverError && (
                <div className="alert alert-danger" role="alert">
                  {errors.serverError}
                </div>
              )}

              {(results > 0 || results == null) && (
                <button
                  onClick={() => {
                    // console.log('formatted', {
                    //   ...formatUrlObject({
                    //     ...values,

                    //   })
                    // })
                    const hrefWithParameters = 'https://www.beachhousesinparadise.com/rentals?' + (location.location.search ? `${location.location.search.slice(1)}&` : '');
                    //window.location.href = `${hrefWithParameters}$
                    console.log('values', values)
                    const queryString = new URLSearchParams({
                      ...formatUrlObject({
                        ...values,

                      }),
                      // preserveCheckIn: true,
                    }).toString();
                    window.location.href = `${hrefWithParameters}${queryString}`;
                    // updateUrl('rentals', {
                    //   ...formatUrlObject({
                    //     ...values,

                    //   }),
                    //   // preserveCheckIn: true,
                    // });
                  }}
                  type="button"
                  disabled={isSubmitting || !objectIsEmpty(errors)}
                  className={`responsive-button btn btn-secondary text-primary font-weight-bold rounded w-100 mb-2`}
                >
                  {!loading && <span>See Results</span>}
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </button>
              )}
              {results > 0 ? (

                <p className="mb-1 d-none">
                  Matches: <b>{results}</b> found
                </p>


              ) : (
                results != null && <div
                  className="alert alert-warning d-flex px-1plus my-0"
                  role="alert"
                >
                  <span className="mt-1plus">
                    <img weight="30" height="30" src="/alert-info.svg" />
                  </span>
                  <span className="ml-1plus font-color-black">
                    Matches: <b>{results}</b> found with your selected filters.
                    Please modify your search
                  </span>
                </div>
              )}
            </div>
            <div className="text-right">
              <button type="button" onClick={() => {
                resetForm();
                navigate(`/rentals`);

              }} className="btn btn-hyperlink p-0 text-primary">
                Clear Form
              </button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default WithLocation(CheckAvailabilityForm);
