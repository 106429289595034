import React, { useEffect, useState, useRef } from 'react';

import { primary } from '../../styles/styles.scss';
import { StaticQuery, graphql, Link } from 'gatsby';
import { getSiteMeta } from '../../util/providers';
import Modal from '../Modal';
import LazyImage from '../LazyImage';
import ContactUs from '../ContactUs';
import PropertySearchForm from '../PropertySearchForm';
import DynamicPropertyList from '../DynamicPropertyList';
import { getVersionMap } from '../../util/helpers';
import WithLocation from '../WithLocation';
import Cookies from 'js-cookie';
import { AlertTriangle, Check } from 'react-feather';
import { auto } from 'eol';
import styles from './styles.module.scss';
import './styles.scss';

const buildPropertyDropdownList = filteredPropertyList => {
  const LeftColumn = ({ children }) => (
    <div className={`dropdown-column pr-lg-0`}>{children}</div>
  );
  const RightColumn = ({ children }) => (
    <div className={`  pl-lg-0`}>{children}</div>
  );
  const leftColumnChildren = [];
  const rightColumnChildren = [];
  const propertyListLength = filteredPropertyList.length;
  const splitPropertyColumnIndex =
    propertyListLength % 2 === 0
      ? propertyListLength / 2
      : (propertyListLength + 1) / 2;
  filteredPropertyList.forEach((property, index) => {
    const file = property?.sitePage?.featuredImageSecondary?.file || {};
    const versionMap = getVersionMap(file);

    const link = (
      <Link
        data-dropdown-link
        key={property.sitePage.slug}
        className={`${styles.propertyDropdownList} dropdown-item d-flex align-items-center`}
        to={`/${property.sitePage.slug}`}
      >
        <div className="position-relative overflow-hidden">
          {versionMap && (
            <LazyImage
              src={versionMap && versionMap.tiny.fullUrl}
              srcset={`${versionMap && versionMap.small.fullUrl} ${versionMap &&
                versionMap.small.width}w`}
              itemProp="thumbnail"
              className="no-resize"
              width="92"
              style={{ width: '92px' }}
            />
          )}
        </div>
        <div
          className={`${styles.propertyList} flex-grow-1 ml-1plus property-list`}
        >
          <span className="text-primary font-weight-bold">
            {property.sitePage.title}
            {property.status === 'NEW' && (
              <span className="ml-1 text-primary">
                (<span className={`${styles.newPropertyText}`}>New</span>)
              </span>
            )}
          </span>
          <br />
          <div className="d-flex">
            <span className="mr-1plus" title="Bedrooms">
              <span className="mr-1 align-text-bottom">
                <img width="26" height="20" src="/bed.svg" alt="Bedrooms" />
              </span>
              <span>{property.bedrooms}</span>
            </span>
            <span className="mr-1plus" title="Baths">
              <span className="mr-1 align-text-bottom">
                <img width="20" height="20" src="/bath.svg" alt="Bathrooms" />
              </span>
              {property.bathrooms}
            </span>
            <span className="mr-1plus" title="Sleeps">
              <span className="mr-1 align-text-bottom">
                <img width="16" height="20" src="/sleeps.svg" alt="Sleeps" />
              </span>
              {property.sleeps}
            </span>
          </div>
        </div>
      </Link>
    );
    if (index + 1 <= splitPropertyColumnIndex) {
      leftColumnChildren.push(link);
    } else {
      rightColumnChildren.push(link);
    }
  });
  return (
    <div className="d-block d-lg-flex my-lg-1">
      <LeftColumn>{leftColumnChildren}</LeftColumn>
      <RightColumn>{rightColumnChildren}</RightColumn>
    </div>
  );
};

const HeaderNavigation = ({ location, search }) => {
  const [searchModalIsOpen, setSearchModalIsOpen] = useState(false);
  const [contactUsModalIsOpen, setContactUsModalIsOpen] = useState(false);
  //const [siteAlertModalIsOpen, setSiteAlertModalIsOpen] = useState(false);
  // const [siteAlertBannerShow, setSiteAlertBannerShow] = useState(false);
  const [sitePromoModalIsOpen, setSitePromoModalIsOpen] = useState(false);
  const { sitealert = true, utm_campaign = '', platform, nodisc, onlinebooking, promo } = search;
  const navbarSupportedContent = useRef(null);

  const siteAlertBannerShow = sitealert && utm_campaign.indexOf('VCR') === -1;

  if (typeof window != 'undefined') {
    if (location.location.origin.match(/bhipproperties|bhiprentals/)) {
      window.location.href = '/landing'
      return <></>
    }
  }
  const utmParams = {};

  for (const [key, value] of Object.entries(search)) {
    if (key.startsWith('utm_')) {
      utmParams[key] = value;
    }
  }

  useEffect(() => {
    // always remove canvas when switching routes
    // https://gitlab.bhipdevelopment.com/bhip/bhip-public/-/issues/153#note_3401
    $('body').removeClass('offcanvas-open');
    if (utm_campaign && platform) {
      Cookies.set('platform', platform);
    }
    if (nodisc) {
      Cookies.set('nodisc', nodisc);
    }
    if (promo) {
      Cookies.set('promo', promo);
    }
    if (onlinebooking) {
      Cookies.set('onlinebooking', onlinebooking);
    }
    if (Object.keys(utmParams).length > 0) {
      Cookies.set('utmparams', JSON.stringify(utmParams));
      // const decoded = Cookies.get('utmparams');
      // console.log('decoded', JSON.parse(decoded))
    }

    // if (datesearch) {
    //   Cookies.set('datesearch', datesearch);
    // }
    if (utm_campaign.indexOf('VCR') > -1) {
      // Cookies.set('siteAlertModalWasOpened', 'true');
      setSitePromoModalIsOpen(true);
    } else if (sitealert === true) {
      // setSiteAlertBannerShow(true);
      // const siteAlertModalWasOpened =
      //   Cookies.get('siteAlertModalWasOpened') || false;
      // //
      // if (!siteAlertModalWasOpened) {
      //   Cookies.set('siteAlertModalWasOpened', 'true');
      //   setTimeout(() => {
      //     setSiteAlertModalIsOpen(true);
      //   }, 3000);
      // }
    }
    // const jumpMenuDropdowns =
    //   document.querySelectorAll('[data-sticky-jump-menu] .dropdown-menu') || [];
    $(document).on('click', '[data-dropdown-link]', () => {
      $(navbarSupportedContent.current).collapse('hide');
      $('body').removeClass('offcanvas-open');
    });
    $(document)
      .on('show.bs.collapse', navbarSupportedContent.current, function () {
        $(navbarSupportedContent.current).show();
        $('body').addClass('offcanvas-open');
      })
      .on('hide.bs.collapse', navbarSupportedContent.current, function () {
        $(navbarSupportedContent.current).hide();
        $('body').removeClass('offcanvas-open');
      });
    $(document).on('click', '[data-sticky-jump-menu] .dropdown-menu', () => {
      $(navbarSupportedContent.current).collapse('hide');
      $('body').removeClass('offcanvas-open');
    });

    return () => {
      $(document)
        .find('[data-dropdown-link]')
        .unbind('click');
    };
  }, []);

  const siteMeta = getSiteMeta();
  return (
    <StaticQuery
      query={graphql`
        query PropertiesQuery {
          bhip {
            properties {
              status
              bedrooms
              bathrooms
              sleeps
              key
              type
              sitePage {
                slug
                title
                status
                featuredImageSecondary {
                  file {
                    versions {
                      key
                      width
                      height
                      fullUrl
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        // const filteredPropertyList = data.bhip.properties.filter(
        //   property =>
        //     property.sitePage !== null &&
        //     property.type !== 'COMBO' &&
        //     property?.sitePage?.status === 'PUBLISHED'
        // );
        const filteredPropertyList = data.bhip.properties.filter(
          property =>
            (property.type == 'COMBO' ||
              property?.sitePage?.status === 'PUBLISHED') &&
            property.sitePage !== null
        );

        const PropertyDropdownList = buildPropertyDropdownList(
          filteredPropertyList
        );

        return (
          <header className={`bg-white`}>


            <div
              onClick={() => {
                $(navbarSupportedContent.current).collapse('hide');
              }}
              className={`${styles.navBackground} nav-background`}
            />
            <nav
              id="header-nav"
              className="navbar navbar-expand-lg p-0 p-lg-2 d-flex justify-content-between position-relative"
              style={{ zIndex: 5 }}
            >
              <div
                className={`${styles.flexItem} d-none d-lg-flex pl-lg-1 pl-xl-3`}
              >
                <Link className="navbar-brand" to="/">
                  <img
                    height="50"
                    width="220"
                    src="/logo-bhip.svg"
                    alt="Beach Houses In Paradise"
                  />
                </Link>
              </div>
              <div
                id={styles.desktopHeaderDropdown}
                className={`${styles.flexItem} flex-grow-md-1 text-center-md w-100-sm-down`}
              >
                <div
                  className={`d-flex justify-content-between align-items-center`}
                >
                  <div className="p-1">
                    <button
                      id="mobile-menu-button"
                      className={`navbar-toggler collapsed`}
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className={`icon-bar bar1`} />
                      <span className={`icon-bar bar2`} />
                      <span className={`icon-bar bar3`} />
                    </button>
                  </div>
                  <div className="d-lg-none">
                    <Link
                      data-dropdown-link
                      className={`${styles.bhipLogo} nav-link`}
                      to="/"
                    >
                      <img
                        height="50"
                        width="220"
                        src="/logo-bhip.svg"
                        alt="Beach Houses In Paradise"
                      />
                    </Link>
                  </div>
                  <div className="d-lg-none">
                    <button
                      className={`${styles.contactUsModalToggle} nav-link btn btn-link`}
                      onClick={() => setContactUsModalIsOpen(true)}
                      title="Contact Us"
                    >
                      <img
                        // width="26"
                        className="no-resize"
                        height="28"
                        src="/contact.svg"
                        alt="Contact Us"
                      />
                    </button>
                  </div>
                </div>
                <div
                  ref={navbarSupportedContent}
                  className={`collapse navbar-collapse ${styles.navbarCollapse}`}
                  id="navbarSupportedContent"
                >
                  <ul
                    className={`${styles.navbarUL} navbar-nav m-auto`}
                    role="menu"
                    aria-labelledby="mobile-menu-button"
                  >
                    <li
                      className={`${styles.navLi} nav-item p-lg-1 d-lg-none`}
                      role="none"
                    >
                      <a
                        href="#"
                        id="search-rentals-button"
                        onClick={() => setSearchModalIsOpen(true)}
                        data-dropdown-link
                        className="nav-item-link nav-link text-primary"
                        role="menuitem"
                      >
                        Search Rentals
                      </a>
                    </li>
                    <li
                      role="none"
                      id="property-list-dropdown"
                      className={`${styles.navLi} nav-item dropdown  p-lg-1`}
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="beachhousesDropdown"
                        role="menuitem"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Beach Houses
                      </a>
                      <div
                        className={`${styles.navbarDropdownMenu} dropdown-menu rounded-0`}
                        aria-labelledby="beachhousesDropdown"
                        id="beachhouses-dropdown-menu"
                      >
                        <DynamicPropertyList
                          id={'header-menu'}
                          properties={filteredPropertyList}
                        />
                      </div>
                    </li>
                    <li
                      className={`${styles.navLi} nav-item dropdown rounded-0 p-lg-1`}
                      role="none"
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="compareDropdown"
                        role="menuitem"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Compare Properties
                      </a>
                      <div
                        className="dropdown-menu rounded-0"
                        aria-labelledby="compareDropdown"
                      >
                        <Link
                          data-dropdown-link
                          className="dropdown-item"
                          to="/availability"
                          role="menuitem"
                        >
                          By Availability
                        </Link>
                        <Link
                          data-dropdown-link
                          className="dropdown-item"
                          to="/rentals?mode=map"
                          role="menuitem"
                        >
                          By Location (Map View)
                        </Link>
                        <Link
                          data-dropdown-link
                          className="dropdown-item"
                          to="/property-overview"
                          role="menuitem"
                        >
                          By Feature
                        </Link>
                      </div>
                    </li>
                    <li
                      className={`${styles.navLi} nav-item dropdown pl-0 pr-0 p-lg-1`}
                      role="none"
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="toursDropdown"
                        role="menuitem"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Tours
                      </a>
                      <div
                        className="dropdown-menu rounded-0"
                        aria-labelledby="toursDropdown"
                      >
                        <Link
                          data-dropdown-link
                          className="dropdown-item"
                          to="/video-tours-by-home"
                          role="menuitem"
                        >
                          Video
                        </Link>
                        {/* <Link
                          data-dropdown-link
                          className="dropdown-item"
                          to="/virtual-tours"
                          role="menuitem"
                        >
                          3D
                        </Link> */}
                        <Link
                          data-dropdown-link
                          className="dropdown-item"
                          to="/birds-eye-view"
                          role="menuitem"
                        >
                          Bird's Eye View
                        </Link>
                        <Link
                          data-dropdown-link
                          className="dropdown-item"
                          to="/rentals?mode=map"
                          role="menuitem"
                        >
                          Map View
                        </Link>
                      </div>
                    </li>
                    <li
                      role="none"
                      className={`${styles.navLi} nav-item p-lg-1`}
                    >
                      <Link
                        to="/rentals?mode=map"
                        data-dropdown-link
                        className="nav-link nav-item-link"
                        role="menuitem"
                      >
                        Map
                      </Link>
                    </li>
                    <li
                      role="none"
                      className={`${styles.navLi} nav-item p-lg-1`}
                    >
                      <Link
                        to="/news"
                        data-dropdown-link
                        className="nav-link nav-item-link"
                        role="menuitem"
                      >
                        News
                      </Link>
                    </li>
                    <li
                      role="none"
                      className={`${styles.navLi} nav-item p-lg-1`}
                    >
                      <Link
                        to="/gift-certificates"
                        data-dropdown-link
                        className="nav-item-link nav-link text-nowrap"
                        role="menuitem"
                      >
                        <img src="/icon-gift-certificate.svg" className="mr-1" /> Gift Certificates
                      </Link>
                    </li>
                    <li
                      role="none"
                      className={`${styles.navLi} nav-item p-lg-1 d-lg-none`}
                    >
                      <button
                        role="menuitem"
                        onClick={() => setContactUsModalIsOpen(true)}
                        data-dropdown-link
                        className="nav-item-link nav-link text-primary btn btn-link"
                      >
                        Contact Us
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                id="contact-us-button"
                className={`${styles.contactContainer} ${styles.flexItem} d-none d-lg-flex text-nowrap justify-content-end pr-lg-1 pr-xl-3`}
              >
                <div className="dropdown">
                  <button
                    className={`${styles.contactUsDropdown} btn btn-link`}
                    onClick={() => setContactUsModalIsOpen(true)}
                    title="Contact Us"
                  >
                    <img
                      className="no-resize mr-1"
                      width="26"
                      height="20"
                      src="/contact.svg"
                      alt="Contact Us"
                    />
                    <span className={`${styles.contactUsText} align-middle`}>
                      Contact Us
                    </span>
                  </button>
                </div>
              </div>
            </nav>
            <Modal
              expanded={true}
              isOpen={contactUsModalIsOpen}
              title={'Contact Us'}
              onClose={() => setContactUsModalIsOpen(false)}
            >
              <ContactUs onClose={() => setContactUsModalIsOpen(false)} />
            </Modal>
            <Modal
              isOpen={searchModalIsOpen}
              title={'Beach House Rentals'}
              onClose={() => setSearchModalIsOpen(false)}
            >
              <PropertySearchForm
                modalIsOpen={searchModalIsOpen}
                id="header-search-form"
                onClose={() => setSearchModalIsOpen(false)}
              />
            </Modal>

            <Modal
              isOpen={sitePromoModalIsOpen}
              disableFocusTrap={true}
              title={'Victory Celebration Rates'}
              onClose={() => setSitePromoModalIsOpen(false)}
              expanded={true}
            >
              <div>
                <p className="mt-1">
                  Please mention promo code 'VCR' (Victory Celebration Rates)
                  when corresponding with us via email, telephone and on all
                  vacation-rental platforms.
                </p>

                {/* <div class="row no-gutters">
                  <div class="col-12 col-sm-6 col-md-8">.col-12 .col-sm-6 .col-md-8</div>
                  <div class="col-12 col-sm-4">.col-6 .col-md-4</div>
                </div> */}
                <p>
                  <img src="https://images.beachhousesinparadise.com/uploads/2020/04/vcr-landing-v2.jpg" />
                </p>
                <b>About Beach Houses in Paradise:</b>
                <p>
                  Beach Houses in Paradise offers more than two-dozen five-star
                  luxury beach properties, all located on a remote barrier
                  island off of Florida's Treasure Coast (where the effects of
                  the pandemic were substantially lower than other areas of
                  Florida and the larger USA). Yet these homes are located only
                  a short 1-hour drive away from major international airports.
                  Renting America's most luxurious oceanfront beach houses for
                  20 years.
                </p>

                <div className="text-center">
                  <button
                    className="btn btn-outline-primary px-5 my-1"
                    onClick={() => setSitePromoModalIsOpen(false)}
                  >
                    Ok, got it.
                  </button>
                </div>
              </div>
            </Modal>

            {/* <Modal
              isOpen={siteAlertModalIsOpen}
              disableFocusTrap={true}
              title={'Beach Houses are Available during COVID-19'}
              onClose={() => setSiteAlertModalIsOpen(false)}
              expanded={true}
            >
              <div style={{ overflow: 'auto', height: '400px' }}>
                <p className="mt-1">
                  Our Beach Houses in Paradise are located on a barrier island
                  on Florida's remote Treasure Coast named "South Hutchinson
                  Island". Located far away from big cities and travel hubs, the
                  barrier-island location has experienced substantially lower
                  incidence of coronavirus than other areas of the country.
                  (Connected to the mainland by 3 long bridges, the island is 25
                  miles long and the nearest towns are Stuart, Sewall's Point,
                  Jensen Beach and Ft. Pierce.)
                </p>
                <div>
                  Our beach houses are currently available to the following
                  three types of groups:
                  <ul className="checklist mt-2 mb-3">
                    <li>
                      Free and budget-priced housing for COVID-19 medical
                      responders.{' '}
                      <a
                        href="/covid-19-luxury-beach-isolation#responders"
                        onClick={() => {
                          $('[aria-modal]')
                            .parent()
                            .hide();
                          $('#covid-alert').hide();
                        }}
                      >
                        Learn More
                      </a>
                    </li>
                    <li>
                      Executive or Medical Remote Home Office & Family
                      Self-Isolation (commercial-grade fiber internet, 31-day
                      minimum stay requirement).{' '}
                      <a
                        href="/covid-19-luxury-beach-isolation#isolation"
                        onClick={() => {
                          $('[aria-modal]')
                            .parent()
                            .hide();
                          $('#covid-alert').hide();
                          //setSiteAlertModalIsOpen(false)
                        }}
                      >
                        Learn More
                      </a>
                    </li>
                    <li>
                      Short-term stays for travelers engaged in COVID-19
                      response efforts and/or non-vacation "essential services"
                      commercial activities (minimum stay 7 nights, available
                      for check-in at any time).{' '}
                      <a
                        href="/covid-19-luxury-beach-isolation#commerce"
                        onClick={() => {
                          $('[aria-modal]')
                            .parent()
                            .hide();
                          $('#covid-alert').hide();
                          //setSiteAlertModalIsOpen(false)
                        }}
                      >
                        Learn More
                      </a>
                    </li>

                  </ul>
                </div>
                <div className="mb-3">
                  <b>YOUR OWN PRIVATE, UNCROWDED BEACH</b>:
                  <div className="mt-2 mb-3">
                    Most Public beaches in Florida are closed, but our homes
                    have their own private deeded beach and are exempt from the
                    closures. (No crowds, just a lonely and isolated beach all
                    to yourself and your family ... and an ideal place to
                    protect your loved one and isolate on the beach.)
                  </div>
                  <b>5-STAR LUXURY MEETS 5-STAR CLEANLINESS</b>:
                  <ul className="checklist mt-2">
                    <li>
                      We follow hospital-grade standards and meticulously clean,
                      disinfect, and protect your space.
                    </li>
                    <li>
                      A six-person, professional service, spends 36 person-hours
                      cleaning, prior to your arrival.
                    </li>
                    <li>
                      We use heavy-duty disinfecting solutions (including
                      bleach, Clorox, and Lysol) that kill pathogens.
                    </li>
                    <li>
                      We clean and disinfect every horizontal and vertical
                      surface.
                    </li>
                    <li>
                      A 42-point inspection further ensures the safety and
                      health of your family or group.
                    </li>
                  </ul>
                </div>
                <b>HOW TO BOOK</b>:
                <dl className="pt-2 pl-2">
                  <p class="mb-2">
                    <b>STEP 1:</b> Use this site to explore{' '}
                    <Link to="/availability">beach homes by availability</Link>.
                  </p>
                  <b>STEP 2:</b> Use your favorite travel platform to book your
                  reservation. We recommend VRBO / HomeAway or AirBnb. Tip: on
                  either travel platform, search for "Stuart FL", set the filter
                  to "Homes" (to exclude condos) and use the map feature to view
                  a map of results. Our homes are located in Stuart FL, Jensen
                  Beach FL, and Ft. Pierce FL, and they are all on the beach.
                </dl>
                <div className="text-center">
                  <a
                    className="btn btn-outline-primary px-5 my-1"
                    href="/covid-19-luxury-beach-isolation"
                    onClick={() => {
                      $('[aria-modal]')
                        .parent()
                        .hide();
                      $('#covid-alert').hide();
                      //setSiteAlertModalIsOpen(false)
                    }}
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </Modal> */}
          </header>
        );
      }}
    />
  );
};

export default WithLocation(HeaderNavigation);
