import React from 'react';
import styles from '../styles.module.scss';


const SenderInfoSection = ({ values, onChange, onBlur, touched, errors }) => {
    return (
        <>
            <div className="form-group">
                <label htmlFor="fromName">
                    <b>From</b>
                    <span className="text-danger ml-1">*</span>
                </label>
                <input
                    type="text"
                    id="fromName"
                    name="fromName"
                    className="form-control"
                    placeholder="Your name"
                    value={values.formName}
                    onChange={onChange}
                    onBlur={onBlur}
                />
                <div className="small text-danger mt-1">
                    {touched.fromName && errors.fromName && errors.fromName}
                </div>
            </div>
            <div className="form-group">
                <input
                    type="text"
                    id="fromEmail"
                    name="fromEmail"
                    className="form-control"
                    placeholder="youremail@example.com"
                    value={values.fromEmail}
                    onChange={onChange}
                    onBlur={onBlur}
                />
                <div className="small text-danger mt-1">
                    {touched.fromEmail && errors.fromEmail && errors.fromEmail}
                </div>
            </div>
            <div className="form-group mt-3">
                <label htmlFor="message">
                    <b>Additional Information for BHIP</b>

                </label>
                <textarea
                    id="additionalInformation"
                    name="additionalInformation"
                    className="form-control"
                    placeholder="Provide any additional information that you would like Beach Houses in Paradise to know. This information will not be shared with the recipient of the gift certificate."
                    value={values.additionalInformation}
                    onChange={onChange}
                    onBlur={onBlur}
                    maxLength={1000}
                    rows={4}
                />
                <div className={styles.charCount}>
                    ({1000 - values.additionalInformation.length} characters remain)
                </div>
                <div className="small text-danger mt-1">
                    {touched.additionalInformation && errors.additionalInformation && errors.additionalInformation}
                </div>
            </div>
        </>
    );
};

export default SenderInfoSection;
