import React from 'react';
import { GIFT_AMOUNTS } from '../constants';

const GiftAmountSection = ({ value, onChange, onBlur, touched, error }) => {
    return (
        <div className="form-group">
            <label htmlFor="giftCertificateId">
                <b>$ Amount</b>
                <span className="text-danger ml-1">*</span>
            </label>
            <select
                id="giftCertificateId"
                name="giftCertificateId"
                className="form-control custom-select-list"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            >
                <option value="" hidden>Choose $ Amount</option>
                {GIFT_AMOUNTS.map(({ id, amount }) => (
                    <option key={id} value={id}>${amount}</option>
                ))}
            </select>
            <div className="small ml-1plus">(10% discount applied at checkout)</div>
            <div className="small text-danger mt-1">
                {touched && error && error}
            </div>
        </div>
    );
};

export default GiftAmountSection;
