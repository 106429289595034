import React, { Component } from 'react';
import Helmet from 'react-helmet';
import HeaderMenu from '../../components/HeaderMenu';
import PropertySearchbarHeader from '../../components/PropertySearchbarHeader';
import Footer from '../../components/Footer';
import PropTypes from 'prop-types';
import { Modal as LastMinuteDealsModal } from '../../components/LastMinuteDeals';
import Modal from '../../components/Modal';
import './styles.scss';

const EmailLandingLayout = props => {
    const { children, footer, subheader, sidebar, stickyRow, hideSearchBar } = props;
    return (
        <>
            <header className={`bg-white`}>HEADER</header>
            <div className={`${subheader ? 'sticky-row' : ''}`}>
                <>
                    <div className={`bg-primary`}>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    {subheader && (
                                        <PropertySearchbarHeader context="search bar" {...props} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                {stickyRow && stickyRow()}
            </div>
            <main>{children}</main>
            {footer && <Footer />}
        </>
    );
};

EmailLandingLayout.defaultProps = {
    footer: true,
    subheader: true,
    sidebar: false,
};

export default EmailLandingLayout;
