import React from 'react';
import styles from '../styles.module.scss';

const MessageSection = ({ value, onChange, onBlur, touched, error }) => {
    return (
        <div className="form-group mt-2">
            <label htmlFor="message">
                <b>Message</b>
                <span className="text-danger ml-1">*</span>
            </label>
            <textarea
                id="message"
                name="message"
                className="form-control"
                placeholder="Wishing you sun, sand, and unforgettable memories at your dream beach house! Enjoy this gift certificate and your stay in paradise."
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                maxLength={500}
                rows={4}
            />
            <div className={styles.charCount}>
                ({500 - value.length} characters remain)
            </div>
            <div className="small text-danger mt-1">
                {touched && error && error}
            </div>
        </div>
    );
};

export default MessageSection;
