import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    giftCertificateId: Yup.string().required('Gift Certificate Amount is required'),
    recipientName: Yup.string().required("Recipient's name is required"),
    deliveryMethod: Yup.string().required('Delivery method is required'),
    recipientEmail: Yup.string().when('deliveryMethod', {
        is: (val) => val === 'email' || val === 'both',
        then: Yup.string()
            .required('Recipient email is required')
            .email('Invalid email format'),
    }),
    streetAddress: Yup.string().when('deliveryMethod', {
        is: (val) => val === 'overnight' || val === 'both',
        then: Yup.string().required('Street address is required'),
    }),
    city: Yup.string().when('deliveryMethod', {
        is: (val) => val === 'overnight' || val === 'both',
        then: Yup.string().required('City is required'),
    }),
    state: Yup.string().when('deliveryMethod', {
        is: (val) => val === 'overnight' || val === 'both',
        then: Yup.string().required('State is required'),
    }),
    country: Yup.string().when('deliveryMethod', {
        is: (val) => val === 'overnight' || val === 'both',
        then: Yup.string().required('Country is required'),
    }),
    postalCode: Yup.string().when('deliveryMethod', {
        is: (val) => val === 'overnight' || val === 'both',
        then: Yup.string()
            .required('ZIP code is required')
            .matches(/^\d{5}(-\d{4})?$/, 'Invalid ZIP code format'),
    }),
    message: Yup.string()
        .required('Message is required')
        .max(500, 'Message cannot exceed 500 characters'),
    fromName: Yup.string().required('From name is required'),
    fromEmail: Yup.string()
        .required('From email is required')
        .email('Invalid email format'),
});
